import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import AppSelect from "src/components/form/AppSelect";
import AppTextArea from "src/components/form/AppTextarea";
import ModuleHeader from "src/components/ui/ModuleHeader";
import ModuleWrapper from "src/components/ui/ModuleWrapper";
import AppUploadInput from "src/components/form/AppUploadInput";
import AppInput, { InputTypes } from "src/components/form/AppInput";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";
import DeleteIcon from "src/assets/images/delete.svg";

import { useSendFeedback } from "./feedback.hooks";
import feedbackSchema from "./feedback.schema";
import Text from "src/components/ui/Text";
import Label from "src/components/ui/Label";

const Feedback = () => {
  const categoryOptions = [
    { value: "Bład w systemie", label: "Bład w systemie" },
    { value: "Pytanie", label: "Pytanie" },
  ];
  const [file, setFile] = useState<File>(null);

  const currentUser = useCurrentUser();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors: formErrors },
  } = useForm({
    resolver: yupResolver(feedbackSchema),
    reValidateMode: "onChange",
  });
  const { isSended, isSending, handleSendFeedback } = useSendFeedback();

  useEffect(() => {
    if (currentUser) {
      reset({ firstName: currentUser?.firstName, lastName: currentUser?.lastName, email: currentUser?.email });
    }
  }, [currentUser]);

  useEffect(() => {
    if (isSended)
      reset({
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
        email: currentUser?.email,
        category: null,
        subject: null,
        content: null,
        file: null,
      });
    setFile(null);
  }, [isSended]);

  const onSubmit = (data: any) => {
    if (file) data.file = file;
    data.name = `${data.firstName} ${data.lastName}`;
    delete data.firstName;
    delete data.lastName;

    handleSendFeedback(data);
  };

  return (
    <ModuleWrapper>
      <ModuleHeader title="Help" />
      <div className="flex justify-center">
        <div className="flex flex-col space-y-6 bg-white rounded-[20px] h-full p-6 w-[80%]">
          <div className="flex flex-row space-x-4 justify-center">
            <div className="w-[25%] flex flex-col space-y-1">
              <Label>{t("First-name")}</Label>
              <AppInput
                id="firstName"
                register={register}
                inputType={InputTypes.TEXT}
                placeholder={t("First-name")}
                inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                error={!!formErrors.firstName}
              />
            </div>
            <div className="w-[25%] flex flex-col space-y-1">
              <Label>{t("Last-name")}</Label>
              <AppInput
                id="lastName"
                register={register}
                inputType={InputTypes.TEXT}
                placeholder={t("Last-name")}
                inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                error={!!formErrors.lastName}
              />
            </div>
            <div className="w-[25%] flex flex-col space-y-1">
              <Label>{t("Email")}</Label>
              <AppInput
                id="email"
                register={register}
                inputType={InputTypes.TEXT}
                placeholder={t("Email")}
                inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                error={!!formErrors.email}
              />
            </div>
          </div>
          <div className="flex flex-row space-x-4 justify-center">
            <div className="w-[25%] flex flex-col space-y-1">
              <Label>{t("Category")}</Label>
              <Controller
                control={control}
                name="category"
                render={({ field }) => (
                  <AppSelect
                    options={categoryOptions}
                    field={field}
                    placeholder="Wybierz..."
                    error={!!formErrors.category}
                  />
                )}
              />
            </div>
            <div className="w-[25%] flex flex-col space-y-1">
              <Label>{t("Subject")}</Label>
              <AppInput
                id="subject"
                register={register}
                inputType={InputTypes.TEXT}
                placeholder={t("Temat")}
                inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                error={!!formErrors.subject}
              />
            </div>
            <div className="w-[25%] flex flex-col space-y-1">
              <Label>{t("Attachment")}</Label>
              <div className="flex flex-row items-center 2xl:h-12 xl:h-10 h-8 space-x-1">
                {file ? (
                  <>
                    <Text className="text-text-secondary truncate">{file.name}</Text>
                    <img className="cursor-pointer" src={DeleteIcon} onClick={() => setFile(null)} />
                  </>
                ) : (
                  <AppUploadInput
                    customClass="rounded-[12px]"
                    textClass="font-sf-pro-medium text-white"
                    onClick={(files) => setFile(files[0])}
                    fileType="application/pdf, image/*"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-1 items-center">
            <div className="w-[78%] flex flex-col space-y-1">
              <Label>{t("Description")}</Label>
              <AppTextArea
                id="content"
                register={register}
                rows={5}
                placeholder="Opisz problem..."
                error={!!formErrors.content}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <div className="w-[25%]">
              <AppButton
                title="Wyślij zgłoszenie"
                color={ButtonColorTypes.PRIMARY}
                customClass="rounded-[8px]"
                textClass="text-white font-sf-pro-medium"
                isLoading={isSending}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default Feedback;
