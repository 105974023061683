import React from "react";
import { useTranslation } from "react-i18next";

import { Subscription } from "src/api/graphql/types-and-hooks";

import { customTwMerge } from "src/utils/twMerge";

import ProsIcon from "src/assets/images/ellipse-green.svg";
import ConsIcon from "src/assets/images/ellipse-gray.svg";

import Text from "./Text";
import Pill from "./Pill";

type Props = {
  data: Partial<Subscription>;
  isCurrent?: boolean;
  selectedSubscriptionId: string;
  onSelectSubscription: (id: string) => void;
};

const SubscriptionComponent: React.FC<Props> = ({
  isCurrent = false,
  data: { _id, name, subtotal, optionsIncluded, optionsExcluded, trialSubtotal, trialTotal, trialVat },
  selectedSubscriptionId,
  onSelectSubscription,
}) => {
  const { t } = useTranslation();
  const combinedContainerClasses = customTwMerge(
    "w-full h-full xl:py-8 py-4 px-5 rounded-[20px] bg-white shadow-md border-4 border-transparent",
    selectedSubscriptionId === _id && "border-4 border-brand-secondary",
    isCurrent && "opacity-50 pointer-events-none",
  );

  const renderOptionsIncluded = () => {
    return optionsIncluded.map((option, i) => (
      <div className="flex flex-row space-x-2" key={`${name}-included-${i}`}>
        <img src={ProsIcon} alt="ellipse" />
        <Text className="text-text-secondary">{option}</Text>
      </div>
    ));
  };

  const renderOptionsExcluded = () => {
    return optionsExcluded.map((option, i) => (
      <div className="flex flex-row space-x-2" key={`${name}-included-${i}`}>
        <img src={ConsIcon} alt="ellipse" />
        <Text className="text-text-secondary">{option}</Text>
      </div>
    ));
  };

  return (
    <div className={combinedContainerClasses}>
      <div className="flex flex-col space-x-4 items-center">
        <Text className="2xl:text-28 xl:text-24 text-20 font-sf-pro-bold">Pakiet</Text>
        <Text className="2xl:text-36 xl:text-32 text-28 font-sf-pro-bold">{name}</Text>
        <div className="flex flex-col">
          <div>
            <Text className="2xl:text-42 xl:text-38 text-34 font-sf-pro-bold mr-1">{trialSubtotal}</Text>
            <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">zł netto za 1 miesiąc *</Text>
          </div>
          <div className="flex justify-end">
            <Text className="text-text-secondary">potem {subtotal} zł netto/miesiąc</Text>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-3 mt-[16px]">
        {renderOptionsIncluded()}
        {renderOptionsExcluded()}
      </div>
      <div className="flex flex-row justify-center mt-8">
        {isCurrent ? (
          <Pill
            title={t("Current-subscription")}
            bgColor="bg-blue"
            titleColor="text-white"
            customClass="2xl:max-w-[250px] xl:max-w-[200px] max-w-[150px] 2xl:h-10 xl:h-9 h-8"
            textClassName="2xl:text-18 xl:text-16 text-12 mx-2"
          />
        ) : (
          <div className="cursor-pointer" onClick={() => onSelectSubscription(_id)}>
            <Pill
              title={t("Select-subscription")}
              bgColor={`${selectedSubscriptionId === _id ? "bg-brand-secondary" : "bg-blue"}`}
              titleColor="text-white"
              customClass="2xl:max-w-[250px] xl:max-w-[200px] max-w-[150px] 2xl:h-10 xl:h-9 h-8"
              textClassName="2xl:text-18 xl:text-16 text-12 mx-2"
            />
          </div>
        )}
      </div>
      <div className="mt-2 text-center">
        <Text className="text-text-secondary 2xl:text-14 xl:text-12 text-10">
          * {trialSubtotal} zł netto za 1 miesiąc, potem {subtotal} netto zł miesięcznie.
          <p>Oferta dostępna tylko dla użytkowników, którzy nie korzystali jeszcze z okresu próbnego.</p>
        </Text>
      </div>
    </div>
  );
};

export default SubscriptionComponent;
