import React, { useCallback, useEffect, useState } from "react";

import Text from "src/components/ui/Text";
import Spinner, { SpinnerColors } from "src/components/ui/Spinner";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { customTwMerge } from "src/utils/twMerge";

import OcrErrorIcon from "src/assets/images/file-question-mark.svg";

type Invoice = {
  fileName: string;
  isAdded?: boolean;
  isRejected?: boolean;
  isSaved?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  isOcrError?: boolean;
};

type Props = {
  invoices?: Invoice[];
  onClick: (index: number) => void;
  onSaveInvoices: () => void;
  isLoading?: boolean;
  currentActiveIndex?: number;
};

const InvoicesToAddList: React.FC<Props> = ({
  invoices,
  onClick,
  onSaveInvoices,
  isLoading = false,
  currentActiveIndex,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  useEffect(() => {
    if (currentActiveIndex === null) return;
    setActiveIndex(currentActiveIndex);
  }, [currentActiveIndex]);

  const handleOnClick = useCallback(
    (index: number) => {
      const invoice = invoices[index];
      if (invoice?.isSaved) return;
      setActiveIndex(index);
      onClick(index);
    },
    [onClick],
  );

  return (
    <div className="h-full flex flex-col justify-between bg-white rounded-[20px] px-2 xl:py-6 py-3">
      <div className="flex flex-col space-y-2">
        {invoices.map(({ fileName, isLoading, isAdded, isRejected, isError, isSaved, isOcrError }, i) => {
          const containerClass = customTwMerge(
            "flex flex-row items-center justify-between border-2 rounded-[20px] p-3 cursor-pointer",
            activeIndex === i && "border-blue",
            isAdded && "bg-green",
            isRejected && "bg-state-error",
            isError && "bg-orange-400",
            isSaved && "bg-brand-primary",
          );

          return (
            <div className={containerClass} key={i + 1} onClick={() => !isSaved && handleOnClick(i)}>
              <div className="flex flex-row space-x-2">
                <Text className="2xl:text-14 xl:text-12 text-10">{i + 1}.</Text>
                <Text className="2xl:text-14 xl:text-12 text-10 line-clamp-0">{fileName}</Text>
              </div>
              {isLoading && <Spinner color={SpinnerColors.BLUE} />}
              {!isLoading && isOcrError && (
                <img className="z-10" src={OcrErrorIcon} title="Wystąpił błąd podczas zaczytywania danych" />
              )}
            </div>
          );
        })}
      </div>
      <AppButton
        color={ButtonColorTypes.PRIMARY}
        title="Zapisz"
        textClass="text-white"
        customClass="rounded-[8px]"
        onClick={onSaveInvoices}
        isLoading={isLoading}
      />
    </div>
  );
};

export default React.memo(InvoicesToAddList);
