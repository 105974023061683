import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import Text from "src/components/ui/Text";

import AddSingleInvoice from "src/pages/invoice/addSingleInvoice/AddSingleInvoice";
import InvoiceOptionsModal from "src/pages/invoice/modals/InvoiceOptionsModal";
import AddMultipleInvoices from "src/pages/invoice/addMultipleInvoices/AddMultipleInvoices";

import { getInvoicesPath, getPaymentsCalendarPath } from "src/common/router/routerPaths";

enum AddTypesEnum {
  SINGLE = "SINGLE",
  MULTI = "MULTI",
}

type Props = {
  startAccountValuesDate: Date;
};

const InvoiceAdd: React.FC<Props> = ({ startAccountValuesDate }) => {
  const [addType, setAddType] = useState<AddTypesEnum>(null);
  const [isTypeSelected, setIsTypeSelected] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <div className="m-6 flex flex-col h-full 2xl:space-y-6 xl:space-y-4 space-y-2">
      <div className="h-10 flex flex-row">
        <Text className="2xl:text-28 xl:text-24 text-20 text-text-secondary mr-2.5">{t("Transactions")}</Text>
        <Text className="2xl:text-28 xl:text-24 text-20 text-text-secondary">/</Text>
        <Text className="2xl:text-28 xl:text-24 text-20 ml-2.5 font-sf-pro-bold">
          {addType === AddTypesEnum.MULTI ? t("New-transactions") : t("New-transaction")}
        </Text>
      </div>
      {!isTypeSelected && (
        <InvoiceOptionsModal
          options={[
            { value: AddTypesEnum.SINGLE, label: t("Single-document") },
            { value: AddTypesEnum.MULTI, label: t("Multiple-documents") },
          ]}
          onGoBack={() => navigate(searchParams.get("fromCalendar") ? getPaymentsCalendarPath() : getInvoicesPath())}
          onChange={(val) => setAddType(val as AddTypesEnum)}
          onConfirm={() => setIsTypeSelected(true)}
          cancelText="Cancel"
          subtitle="Select-transaction-add-type"
        />
      )}
      {addType === AddTypesEnum.SINGLE && isTypeSelected && (
        <AddSingleInvoice onGoBack={() => setIsTypeSelected(false)} startAccountValuesDate={startAccountValuesDate} />
      )}
      {addType === AddTypesEnum.MULTI && isTypeSelected && (
        <AddMultipleInvoices
          onGoBack={() => setIsTypeSelected(false)}
          startAccountValuesDate={startAccountValuesDate}
        />
      )}
    </div>
  );
};

export default InvoiceAdd;
