import React from "react";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Modal from "src/components/ui/Modal";
import AppSwitch from "src/components/form/AppSwitch";

type Props = {
  onChange: (value: string) => void;
  onGoBack: () => void;
  onConfirm: () => void;
  options: { value: string; label: string }[];
  cancelText?: string;
  confirmText?: string;
  title?: string;
  subtitle?: string;
};

const InvoiceOptionsModal: React.FC<Props> = ({
  onChange,
  onGoBack,
  onConfirm,
  options,
  cancelText = "Previous",
  confirmText = "Next",
  title = "Add-transaction",
  subtitle = "Select-document-type",
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={true}
      onCancel={onGoBack}
      onConfirm={onConfirm}
      cancelText={cancelText}
      confirmText={confirmText}
      confirmTextClass="text-white"
      cancelTextClass="text-text-secondary"
    >
      <div className="flex flex-col">
        <Text className="2xl:text-42 xl:text-36 text-32 text-white font-sf-pro-medium">{t(title)}</Text>
        <Text className="2xl:text-18 xl-text-16 text-14 text-text-secondary mt-3">{t(subtitle)}</Text>
        <div className="flex justify-center 2xl:mt-10 xl:mt-8 mt-6">
          <AppSwitch
            bgColor="bg-[#F4F4F41A]"
            activeColor="bg-white"
            textClass="text-white 2xl:text-14 xl:text-12 text-10"
            activeTextClass="2xl:text-14 xl:text-12 text-10 text-blue font-sf-pro-bold"
            options={options.map((option) => ({ value: option.value, label: t(option.label) }))}
            onChange={onChange}
          />
        </div>
      </div>
    </Modal>
  );
};

export default InvoiceOptionsModal;
