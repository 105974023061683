import React, { useEffect, useState } from "react";
import { customTwMerge } from "src/utils/twMerge";
import Text from "../ui/Text";

type Option = { value: string; label: string };

type Props = {
  bgColor?: string;
  optHeight?: string;
  textClass?: string;
  activeColor?: string;
  optActiveWidth?: string;
  activeTextClass?: string;
  optInactiveWidth?: string;
  activeSwitchWidth?: string;
  inactiveSwitchWidth?: string;
  options: Option[];
  onChange: (value: string) => void;
  defaultValue?: any;
};

const AppSwitch: React.FC<Props> = ({
  options,
  bgColor = "bg-bg-light-gray4",
  optHeight = "2xl:h-10 xl:h-8 h-6",
  textClass = "",
  activeColor = "bg-blue",
  optActiveWidth = "",
  activeTextClass = "font-sf-pro-bold text-white",
  optInactiveWidth = "",
  onChange,
  defaultValue,
}) => {
  const [selected, setSelected] = useState(defaultValue ? defaultValue : options[0].value);
  const combinedContainerClasses = customTwMerge(
    "flex flex-row w-fit items-center justify-center rounded-r-[20px] rounded-l-[20px]",
    bgColor,
  );
  const combinedActiveSwitchClasses = customTwMerge(
    "flex flex-row w-fit items-center justify-center 2xl:min-w-[160px] xl:min-w-[140px] min-w-[120px] rounded-l-[20px] rounded-r-[20px]",
    optHeight,
    activeColor,
    optActiveWidth,
  );
  const combinedInactiveSwitchClasses = customTwMerge(
    "flex justify-center items-center 2xl:min-w-[160px] xl:min-w-[140px] min-w-[120px] cursor-pointer",
    optHeight,
    optInactiveWidth,
  );

  useEffect(() => {
    if (selected) {
      onChange(selected);
    }
  }, [selected]);

  return (
    <div className={combinedContainerClasses}>
      {options.map((option) => {
        return (
          <div
            key={option.value}
            onClick={() => setSelected(option.value)}
            className={selected === option.value ? combinedActiveSwitchClasses : combinedInactiveSwitchClasses}
          >
            <Text className={selected === option.value ? activeTextClass : textClass}>{option.label}</Text>
          </div>
        );
      })}
    </div>
  );
};

export default AppSwitch;
