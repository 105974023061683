import React from "react";
import { useTranslation } from "react-i18next";

import { Package } from "src/api/graphql/types-and-hooks";

import { customTwMerge } from "src/utils/twMerge";

import Text from "./Text";
import Pill from "./Pill";

type Props = {
  data: Partial<Package>;
  selectedPackageId: string;
  onSelectPackage: (id: string) => void;
};

const PackageComponent: React.FC<Props> = ({
  data: { _id, name, subtotal, OCRPagesCount },
  selectedPackageId,
  onSelectPackage,
}) => {
  const { t } = useTranslation();
  const combinedContainerClasses = customTwMerge(
    "w-full h-full xl:py-8 py-4 space-y-4 px-5 rounded-[20px] bg-white shadow-md border-4 border-transparent",
    selectedPackageId === _id && "border-4 border-brand-secondary",
  );

  return (
    <div className={combinedContainerClasses}>
      <div className="flex flex-col space-x-4 items-center">
        <Text className="2xl:text-20 xl:text-16 text-12 font-sf-pro-bold">{name}</Text>
        <Text className="2xl:text-24 xl:text-20 text-16 font-sf-pro-bold">Pakiet {OCRPagesCount} stron</Text>
        <div className="flex flex-row xl:mt-[16px] mt-[8px] space-x-2 items-center justify-center">
          <Text className="2xl:text-36 xl:text-32 text-28 font-sf-pro-bold">{subtotal}</Text>
          <div className="flex flex-col">
            <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">zł</Text>
            <Text className="text-text-secondary">{t("Net")}</Text>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-center mt-8">
        <div className="cursor-pointer" onClick={() => onSelectPackage(_id)}>
          <Pill
            title={t("Select-package")}
            bgColor="bg-blue"
            titleColor="text-white"
            customClass="2xl:max-w-[250px] xl:max-w-[200px] max-w-[150px] 2xl:h-9 xl:h-8 h-7"
            textClassName="2xl:text-18 xl:text-16 text-12 mx-2"
          />
        </div>
      </div>
    </div>
  );
};

export default PackageComponent;
