import React, { useEffect } from "react";
import moment from "moment";
import { InferType } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import Text from "src/components/ui/Text";
import Label from "src/components/ui/Label";
import Thumnbail from "src/components/ui/Thumbnail";
import AppSelect from "src/components/form/AppSelect";
import AppTextArea from "src/components/form/AppTextarea";
import AppDatePicker from "src/components/form/AppDatePicker";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";
import AppDebouncedSelect from "src/components/form/AppDebouncedSelect";

import { useCompanyCars } from "src/pages/invoice/addMultipleInvoices/multipleInvoice.hooks";
import { useContractors } from "src/pages/invoice/invoice.hooks";

import { InvoiceTypes, PaymentStatus, InvoiceGenres, InvoiceSubtypes } from "src/api/graphql/types-and-hooks";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import { parsePostCode } from "src/utils/parsePostCode";

import invoiceSchema from "./invoice.schema";

type FormValues = Required<InferType<typeof invoiceSchema>>;

type Props = {
  formValues?: Partial<FormValues>;
  startAccountValuesDate?: Date;
  onRejectInvoice: (data: Partial<FormValues>) => void;
  onAddInvoice: (data: FormValues) => void;
};

const MultipleInvoiceForm: React.FC<Props> = ({
  formValues = {},
  startAccountValuesDate,
  onRejectInvoice,
  onAddInvoice,
}) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const { getCompanyCars, cars } = useCompanyCars(currentUser?.company?._id);
  const { handleGetContractors, loading: contractorsLoading, contractors } = useContractors();

  const {
    watch,
    control,
    register,
    setValue,
    setError,
    getValues,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm({
    values: { ...formValues, paymentStatus: PaymentStatus.Unpaid },
    resolver: yupResolver(invoiceSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (watch("genre") === InvoiceGenres.Car) {
      getCompanyCars();
    }
  }, [formValues?.genre]);

  useEffect(() => {
    const postCode = parsePostCode(watch("billFrom.postcode"));
    setValue("billFrom.postcode", postCode, { shouldDirty: true });
  }, [watch("billFrom.postcode")]);

  useEffect(() => {
    const postCode = parsePostCode(watch("billTo.postcode"));
    setValue("billTo.postcode", postCode, { shouldDirty: true });
  }, [watch("billTo.postcode")]);

  const handleAddInvoice = async (data: FormValues) => {
    if (data.paymentDue && moment(data.paymentDue).isSameOrBefore(moment(startAccountValuesDate))) {
      setError("paymentDue", {
        type: "manual",
        message: "Payment-due-start-account-values-date-error",
      });
      return;
    }
    onAddInvoice(data);
  };

  const handleRejectInvoice = () => {
    onRejectInvoice(getValues());
  };

  const handleSelectContractor = (companyId: string, field: "billFrom" | "billTo") => {
    const contractor = contractors?.find((contractor) => contractor.companyId === companyId);
    if (!contractor) return;

    setValue(field, contractor);
  };

  return (
    <div className="bg-white rounded-[20px] 2xl:p-6 xl:p-4 p-2 h-full">
      <div className="flex flex-row space-x-4 pb-4 items-center border-b">
        <div className="flex flex-col 2xl:space-y-2 space-y-1 basis-1/5 min-w-[150px]">
          <Label>{t("Number")}</Label>
          <AppInput
            id="invoiceNumber"
            register={register}
            inputType={InputTypes.TEXT}
            placeholder={t("Invoice-number")}
            inputClasses="font-sf-pro-medium border border-bg-light-gray3"
            error={!!formErrors.invoiceNumber}
          />
        </div>
        {watch("genre") === InvoiceGenres.Car ? (
          <div className="flex flex-col 2xl:space-y-2 space-y-1 basis-1/5 min-w-[150px]">
            <Label>{t("Car")}</Label>
            <Controller
              control={control}
              name="car"
              render={({ field }) => (
                <AppSelect
                  field={field}
                  placeholder={t("Car")}
                  options={cars?.map((car) => ({ label: car.name, value: car._id })) || []}
                  error={!!formErrors.car}
                />
              )}
            />
          </div>
        ) : undefined}
        <div className="flex flex-col 2xl:space-y-2 space-y-1">
          <Label>{t("Type")}</Label>
          <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">
            {t(getValues("type") === InvoiceTypes.Cost ? "Cost" : "Income")}
          </Text>
        </div>
        <div className="flex flex-col 2xl:space-y-2 space-y-1">
          <Label>{t("Genre")}</Label>
          <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t(getValues("genre"))}</Text>
        </div>
        {getValues("subtype") ? (
          <div className="flex flex-col 2xl:space-y-2 space-y-1">
            <Label>{t("Subtype")}</Label>
            <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t(getValues("subtype"))}</Text>
          </div>
        ) : undefined}
      </div>
      <div className="pt-5 flex flex-col">
        <div className="flex flex-row space-x-4">
          <Thumnbail file={getValues("file")} />
          <div className="flex flex-col 2xl:space-y-2 space-y-1 xl:max-w-[30%] max-w-[40%]">
            <Label>{t("Payment-status")}</Label>
            <Text className="2xl:text-14 xl:text-12 text-10 text-brand-primary">{t("Payment-status-change-info")}</Text>
            <Controller
              control={control}
              name="paymentStatus"
              render={({ field }) => (
                <AppSelect
                  field={field}
                  placeholder="Status płatności"
                  options={[
                    { label: t("Paid"), value: PaymentStatus.Paid },
                    { label: t("Unpaid"), value: PaymentStatus.Unpaid },
                  ]}
                  error={!!formErrors.paymentStatus}
                  disabled
                />
              )}
            />
          </div>
        </div>
        <div className="flex flex-row space-x-4 mt-4">
          <div className="basis-4/12 flex flex-col space-y-2">
            <Label>{t("Search")}</Label>
            <AppDebouncedSelect
              onSearch={handleGetContractors}
              onSelect={(option) => handleSelectContractor(option.value, "billFrom")}
              isLoading={contractorsLoading}
              options={
                contractors?.map((contractor) => ({ value: contractor.companyId, label: contractor.name })) || []
              }
              disabled={getValues("type") === InvoiceTypes.Income}
              placeholder="Wpisz nazwę lub NIP..."
            />
          </div>
          <div className="basis-4/12 flex flex-col space-y-2">
            <Label>{t("Search")}</Label>
            <AppDebouncedSelect
              onSearch={handleGetContractors}
              onSelect={(option) => handleSelectContractor(option.value, "billTo")}
              isLoading={contractorsLoading}
              options={
                contractors?.map((contractor) => ({ value: contractor.companyId, label: contractor.name })) || []
              }
              disabled={getValues("type") === InvoiceTypes.Cost}
              placeholder="Wpisz nazwę lub NIP..."
            />
          </div>
          <div className="basis-4/12 flex flex-col space-y-2"></div>
        </div>
        <div className="mt-8 flex flex-row space-x-4">
          <div className="basis-4/12 flex flex-col space-y-2">
            <Label>{t("Seller")}</Label>
            <AppInput
              id="billFrom.name"
              register={register}
              inputType={InputTypes.TEXT}
              placeholder={t("Company-name")}
              inputClasses="font-sf-pro-medium border border-bg-light-gray3"
              error={!!formErrors.billFrom?.name}
            />
            <AppInput
              id="billFrom.address"
              register={register}
              inputType={InputTypes.TEXT}
              placeholder={t("Address")}
              inputClasses="font-sf-pro-medium border border-bg-light-gray3"
              error={!!formErrors.billFrom?.address}
            />
            <div className="flex flex-row space-x-2">
              <div className="basis-1/2">
                <AppInput
                  id="billFrom.postcode"
                  register={register}
                  inputType={InputTypes.TEXT}
                  placeholder={t("Code")}
                  inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                  error={!!formErrors.billFrom?.postcode}
                />
              </div>
              <div className="basis-1/2">
                <AppInput
                  id="billFrom.city"
                  register={register}
                  inputType={InputTypes.TEXT}
                  placeholder={t("City")}
                  inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                  error={!!formErrors.billFrom?.city}
                />
              </div>
            </div>
            <AppInput
              id="billFrom.country"
              register={register}
              inputType={InputTypes.TEXT}
              placeholder={t("Country")}
              inputClasses="font-sf-pro-medium border border-bg-light-gray3"
              error={!!formErrors.billFrom?.country}
            />
            <AppInput
              id="billFrom.companyId"
              register={register}
              inputType={InputTypes.TEXT}
              placeholder={t("VAT-ID")}
              inputClasses="font-sf-pro-medium border border-bg-light-gray3"
              error={!!formErrors.billFrom?.companyId}
            />
          </div>
          <div className="basis-4/12 flex flex-col space-y-2">
            <Label>{t("Buyer")}</Label>
            <AppInput
              id="billTo.name"
              register={register}
              inputType={InputTypes.TEXT}
              placeholder={t("Company-name")}
              inputClasses="font-sf-pro-medium border border-bg-light-gray3"
              error={!!formErrors.billTo?.name}
            />
            <AppInput
              id="billTo.address"
              register={register}
              inputType={InputTypes.TEXT}
              placeholder={t("Address")}
              inputClasses="font-sf-pro-medium border border-bg-light-gray3"
              error={!!formErrors.billTo?.address}
            />
            <div className="flex flex-row space-x-2 w-full">
              <div className="basis-1/2">
                <AppInput
                  id="billTo.postcode"
                  register={register}
                  inputType={InputTypes.TEXT}
                  placeholder={t("Code")}
                  inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                  error={!!formErrors.billTo?.postcode}
                />
              </div>
              <div className="basis-1/2">
                <AppInput
                  id="billTo.city"
                  register={register}
                  inputType={InputTypes.TEXT}
                  placeholder={t("City")}
                  inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                  error={!!formErrors.billTo?.city}
                />
              </div>
            </div>
            <AppInput
              id="billTo.country"
              register={register}
              inputType={InputTypes.TEXT}
              placeholder={t("Country")}
              inputClasses="font-sf-pro-medium border border-bg-light-gray3"
              error={!!formErrors.billTo?.country}
            />
            <AppInput
              id="billTo.companyId"
              register={register}
              inputType={InputTypes.TEXT}
              placeholder={t("VAT-ID")}
              inputClasses="font-sf-pro-medium border border-bg-light-gray3"
              error={!!formErrors.billTo?.companyId}
            />
          </div>
          <div className="basis-4/12 flex flex-col space-y-2">
            <Label>{`${t("Description")}/${t("Service-name")}`}</Label>
            <AppTextArea id="description" register={register} rows={8} />
          </div>
        </div>
        <div className="mt-8 flex flex-row space-x-4">
          <div className="flex flex-col space-y-2 basis-1/5">
            <Label>{t("Issue-date")}</Label>
            <Controller
              control={control}
              name="issueDate"
              render={({ field }) => <AppDatePicker field={field} error={!!formErrors.issueDate} />}
            />
          </div>
          <div className="flex flex-col space-y-2 basis-1/5">
            <Label>{t("Sale-date")}</Label>
            <Controller
              control={control}
              name="saleDate"
              render={({ field }) => <AppDatePicker field={field} error={!!formErrors.saleDate} />}
            />
          </div>
          <div
            className={`flex flex-col space-y-2 basis-1/5 ${
              formErrors?.paymentDue?.message !== "paymentDue is a required field" && "mb-4"
            }`}
          >
            <Label>{t("Due-date")}</Label>
            <Controller
              control={control}
              name="paymentDue"
              render={({ field }) => (
                <AppDatePicker
                  field={field}
                  error={!!formErrors.paymentDue}
                  errorMessage={
                    formErrors?.paymentDue?.message &&
                    formErrors.paymentDue.message !== "paymentDue is a required field"
                      ? t(formErrors?.paymentDue?.message)
                      : null
                  }
                />
              )}
            />
          </div>
          <div className="flex flex-col space-y-2 basis-1/5">
            <Label>{t("Net-amount")}</Label>
            <AppInput
              id="subtotal"
              register={register}
              inputType={InputTypes.NUMBER}
              inputClasses="font-sf-pro-medium border border-bg-light-gray3"
              error={!!formErrors.subtotal}
            />
            {[InvoiceSubtypes.Insurance].includes(watch("subtype")) ? (
              <>
                <Label>{t("Capital")}</Label>
                <AppInput
                  id="capital"
                  register={register}
                  inputType={InputTypes.NUMBER}
                  inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                  error={!!formErrors.capital}
                />
              </>
            ) : undefined}
          </div>
          <div className="flex flex-col space-y-2 basis-1/5">
            <Label>{t("Gross-amount")}</Label>
            <AppInput
              id="total"
              register={register}
              inputType={InputTypes.NUMBER}
              inputClasses="font-sf-pro-medium border border-bg-light-gray3"
              error={!!formErrors.total}
            />
            {[InvoiceSubtypes.Leasing, InvoiceSubtypes.Insurance].includes(watch("subtype")) ? (
              <>
                <Label>{t("Interest")}</Label>
                <AppInput
                  id="interest"
                  register={register}
                  inputType={InputTypes.NUMBER}
                  inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                  error={!!formErrors.interest}
                />
              </>
            ) : undefined}
          </div>
        </div>
      </div>
      <div className="mt-12 flex flex-row space-x-4 justify-center items-center">
        <div className="min-w-[100px]">
          <AppButton
            color={ButtonColorTypes.GREEN}
            title={t("Add")}
            customClass="rounded-[8px]"
            textClass="text-white"
            onClick={handleSubmit(handleAddInvoice)}
          />
        </div>
        <div className="min-w-[100px]">
          <AppButton
            color={ButtonColorTypes.DANGER}
            title={t("Reject")}
            customClass="rounded-[8px]"
            textClass="text-white"
            onClick={handleRejectInvoice}
          />
        </div>
      </div>
    </div>
  );
};

export default MultipleInvoiceForm;
