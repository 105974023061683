import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import PackageComponent from "src/components/ui/Package";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { usePackages } from "./packageBuy.hooks";

type Props = {
  onChangeStep: () => void;
  onSelectPackage: (id: string) => void;
  selectedPackageId: string;
};

const SelectPackage: React.FC<Props> = ({ selectedPackageId, onSelectPackage, onChangeStep }) => {
  const { t } = useTranslation();
  const { packages } = usePackages({ take: 0, skip: 0 });

  useEffect(() => {
    if (packages?.length) {
      onSelectPackage(packages[0]._id);
    }
  }, [packages]);

  const renderPackages = () => {
    if (!packages) return null;

    return (
      <div className="flex flex-col xl:space-y-12 space-y-8 items-center w-full">
        <div className="flex flex-col space-y-2 items-center">
          <Text className="text-white 2xl:text-32 xl:text-30 text-28">Wybór pakietów</Text>
          <Text className="text-text-secondary 2xl:text-20 xl:text-18 text-14">
            Wybierz pakiet odpowiadający Twoim potrzebom i przejdź do płatności.
          </Text>
        </div>
        <div className="flex flex-row space-x-4 items-center w-full">
          {packages.map((subscription) => (
            <PackageComponent
              data={subscription}
              key={subscription._id}
              selectedPackageId={selectedPackageId}
              onSelectPackage={onSelectPackage}
            />
          ))}
        </div>
        <div className="max-w-[30%] w-full">
          <AppButton
            title={t("Go-to-payment")}
            color={ButtonColorTypes.PRIMARY}
            customClass="rounded-[12px]"
            textClass="text-white 2xl:text-16 xl:text-14 text-12 font-sf-pro-medium"
            onClick={onChangeStep}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="flex items-center 2xl:max-w-[900px] xl:max-w-[700px] max-w-[620px] w-full">{renderPackages()}</div>
  );
};

export default SelectPackage;
