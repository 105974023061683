import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loader from "src/components/ui/Loader";

import InvoiceAdd from "src/pages/invoice/InvoiceAdd";
import { useCompanyAccountBalance } from "src/pages/invoice/invoice.hooks";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { getInvoicesPath } from "src/common/router/routerPaths";

export const CanAddInvoice = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { companyAccountBalance } = useCompanyAccountBalance(currentUser?.company?._id);

  useEffect(() => {
    if (!companyAccountBalance) return;

    if (
      !companyAccountBalance.accountBalance ||
      (companyAccountBalance.taxDetails.isVatPayer && !companyAccountBalance.vatAccountBalance)
    ) {
      navigate(getInvoicesPath());
    }
  }, [companyAccountBalance]);

  if (!companyAccountBalance?.startAccountValuesDate)
    return (
      <div className="flex h-full items-center justify-center">
        <Loader />
      </div>
    );

  return <InvoiceAdd startAccountValuesDate={companyAccountBalance.startAccountValuesDate} />;
};

export default CanAddInvoice;
