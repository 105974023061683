import React from "react";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { customTwMerge } from "src/utils/twMerge";

type Props = {
  title: string;
  onAdd?: () => void;
  className?: string;
  addButtonTitle?: string;
  addButtonLoading?: boolean;
};

const ModuleHeader: React.FC<Props> = ({ title, addButtonTitle, onAdd, className = "", addButtonLoading = false }) => {
  const { t } = useTranslation();
  const combinedClasses = customTwMerge("2xl:h-10 xl:h-8 flex flex-row justify-between mb-4", className);

  return (
    <div className={combinedClasses}>
      <Text className="2xl:text-28 xl:text-24 text-20 font-sf-pro-bold">{t(`${title}`)}</Text>
      {onAdd ? (
        <div>
          <AppButton
            color={ButtonColorTypes.PRIMARY}
            title={t(`${addButtonTitle}`)}
            customClass="rounded-[8px]"
            onClick={onAdd}
            textClass="text-white"
            isLoading={addButtonLoading}
          />
        </div>
      ) : undefined}
    </div>
  );
};

export default ModuleHeader;
