import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import Table from "src/components/ui/Table";
import AppSwitch from "src/components/form/AppSwitch";
import Pagination from "src/components/ui/Pagination";
import ModuleHeader from "src/components/ui/ModuleHeader";
import ModuleWrapper from "src/components/ui/ModuleWrapper";
import ListContainer from "src/components/ui/ListContainer";
import AccountBalanceInfo from "src/components/ui/AccountBalanceInfo";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import {
  SortOptions,
  InvoiceTypes,
  PaymentStatus,
  InvoiceGenres,
  InvoicesListDocument,
  GetInvoicesOptionsInput,
} from "src/api/graphql/types-and-hooks";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { getInvoiceDetailsPath, getInvoiceNewPath, getInvoicesPath } from "src/common/router/routerPaths";

import InvoiceFilters from "./InvoiceFilters";
import InvoicesTableRow from "./InvoicesTableRow";
import ChangeDocumentsPaymentStatus from "./ChangeDocumentsPaymentStatus";
import { useCompanyAccountBalance, useInvoices } from "./invoice.hooks";

const HEADERS = [
  "",
  "Numer/Nazwa",
  "Kontrahent",
  "Termin płatności",
  "Kwota netto",
  "Kwota brutto",
  "Status płatności",
  "",
];

const Invoices = () => {
  const currentUser = useCurrentUser();
  const companyId = currentUser?.company?._id;
  const [searchParams] = useSearchParams();
  const showUnpaidCosts = searchParams.get("showUnpaidCosts");
  const showUnpaidIncomes = searchParams.get("showUnpaidIncomes");

  const [type, setType] = useState<InvoiceTypes>(showUnpaidCosts ? InvoiceTypes.Cost : InvoiceTypes.Income);
  const [genre, setGenre] = useState<InvoiceGenres | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<InvoicesListDocument[]>([]);
  const [showAccountBalanceInfoModal, setShowAccountBalanceInfoModal] = useState(false);

  const filter =
    showUnpaidCosts || showUnpaidIncomes
      ? { paymentStatus: { eq: PaymentStatus.Unpaid }, paymentDue: { lte: moment().subtract(1, "day").unix() } }
      : {};
  const [filteringData, setFilteringData] = useState<GetInvoicesOptionsInput>({
    take: 10,
    skip: 0,
    filter,
    sort: { paymentDue: SortOptions.Desc },
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, invoices, total } = useInvoices({
    ...filteringData,
    filter: {
      ...filteringData.filter,
      ...(type ? { type: { eq: type }, genre: undefined } : { genre: { eq: genre }, type: undefined }),
    },
  });
  const { companyAccountBalance, loading: companyAccountBalanceLoading } = useCompanyAccountBalance(companyId);

  useEffect(() => {
    if (selectedDocuments.length !== 0) setSelectedDocuments([]);
  }, [invoices]);

  const onDocumentClick = (doc: InvoicesListDocument) => {
    if (selectedDocuments.some((d) => d._id === doc._id)) {
      setSelectedDocuments((prevState) => prevState.filter((d) => d._id !== doc._id));
    } else {
      setSelectedDocuments((prevState) => [...prevState, doc]);
    }
  };

  const handleInvoiceClick = (id: string) => {
    // open invoice details in new tab
    window.open(getInvoiceDetailsPath({ invoiceId: id }), "_blank");
  };

  const renderRows = () => {
    if (!invoices) return null;
    return invoices.map((d, i) => {
      return (
        <InvoicesTableRow
          data={d}
          key={d._id}
          onClick={() => handleInvoiceClick(d._id)}
          onClickCheckbox={() => onDocumentClick(d)}
          isSelected={selectedDocuments.some((doc) => doc._id === d._id)}
        />
      );
    });
  };

  const onAddPayment = () => {
    if (
      !companyAccountBalance?.accountBalance ||
      (companyAccountBalance?.taxDetails.isVatPayer && !companyAccountBalance?.vatAccountBalance)
    ) {
      setShowAccountBalanceInfoModal(true);
    } else {
      navigate(getInvoiceNewPath());
    }
  };

  return (
    <>
      {showModal && <ChangeDocumentsPaymentStatus documents={selectedDocuments} onCancel={() => setShowModal(false)} />}
      {showAccountBalanceInfoModal && <AccountBalanceInfo onCancel={() => setShowAccountBalanceInfoModal(false)} />}
      <ModuleWrapper>
        <ModuleHeader
          title="Transactions"
          onAdd={onAddPayment}
          addButtonTitle="Add-transaction"
          addButtonLoading={companyAccountBalanceLoading}
        />
        <div className="flex flex-row justify-between items-center mt-3">
          <AppSwitch
            defaultValue={type}
            options={[
              { value: InvoiceTypes.Income, label: t("Income") },
              { value: InvoiceTypes.Cost, label: t("Cost") },
              { value: InvoiceGenres.Tax, label: t("Taxes") },
            ]}
            onChange={(value) => {
              if (value === InvoiceGenres.Tax) {
                setType(null);
                setGenre(InvoiceGenres.Tax);
              } else {
                setType(value as InvoiceTypes);
                setGenre(null);
              }
            }}
            optActiveWidth="min-w-[100px]"
            optInactiveWidth="min-w-[100px]"
          />
          <div
            className={`border ${selectedDocuments?.length ? "border-blue" : "border-[#00000066]"} rounded-[12px] px-2`}
          >
            <AppButton
              title="Zmień status płatności"
              color={ButtonColorTypes.SECONDARY}
              textClass={`${selectedDocuments?.length ? "text-blue" : "text-text-secondary"}`}
              onClick={() => selectedDocuments.length && setShowModal(true)}
            />
          </div>
        </div>
        <ListContainer
          title={t("Transactions-list")}
          filters={
            <InvoiceFilters
              onChange={(filters) =>
                setFilteringData((prevState) => {
                  return { ...prevState, filter: filters };
                })
              }
              defaultFilters={
                showUnpaidCosts || showUnpaidIncomes
                  ? { paymentStatus: PaymentStatus.Unpaid, paymentDueTo: moment().subtract(1, "day").unix() * 1000 }
                  : {}
              }
            />
          }
        >
          <Table headers={HEADERS} isLoading={loading} rows={renderRows()} />
          {!loading ? (
            <Pagination
              total={total}
              filteringData={filteringData}
              route={getInvoicesPath()}
              onChange={setFilteringData}
            />
          ) : undefined}
        </ListContainer>
      </ModuleWrapper>
    </>
  );
};

export default Invoices;
