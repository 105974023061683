import React, { forwardRef } from "react";

import { customTwMerge } from "src/utils/twMerge";

import Text from "../ui/Text";
import Spinner from "../ui/Spinner";

export enum ButtonColorTypes {
  PRIMARY = "bg-brand-primary",
  SECONDARY = "",
  DANGER = "bg-state-error",
  GREEN = "bg-green",
}

type Props = {
  icon?: string;
  color: ButtonColorTypes;
  title: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  textClass?: string;
  customClass?: string;
  ref?: React.RefObject<HTMLButtonElement>;
};

const AppButton = forwardRef<HTMLButtonElement, Props>(
  ({ icon, color, title, onClick, disabled, textClass = "", isLoading, customClass = "" }, ref) => {
    const combinedClasses = customTwMerge(
      "w-full flex flex-row items-center justify-center 2xl:h-10 xl:h-8 h-6 font-sf-pro-bold px-2 min-w-[70px]",
      color,
      customClass,
      disabled && "opacity-80",
    );

    return (
      <button className={combinedClasses} disabled={disabled || isLoading} onClick={onClick} type="button" ref={ref}>
        {icon && !isLoading && <img className="mr-3" src={icon} />}
        {isLoading ? <Spinner /> : undefined}
        <Text className={textClass}>{title}</Text>
      </button>
    );
  },
);

AppButton.displayName = "AppButton";

export default AppButton;
