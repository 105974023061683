import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

import { removeTypenameFromMutationLink } from "src/utils/omitTypenameField";

const APP_CLIENT_NAME = "web";
// export const API_URL = "http://localhost:3001";
export const API_URL = "https://api.flike.finance"; // prod
// export const API_URL = "https://api.flike.uniquedevs.usermd.net"; // test

const authLink = setContext((_, { headers }) => {
  const accessToken = localStorage.getItem("accessToken");

  return {
    headers: {
      ...headers,
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    },
  };
});
const uploadLink = createUploadLink({ uri: `${API_URL}/graphql`, headers: { "Apollo-Require-Preflight": "true" } });
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      if (extensions?.code === "UNAUTHENTICATED") {
        localStorage.removeItem("accessToken");
      }
      // console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (message.includes("Too Many Requests")) {
        alert("Wysłano zbyt wiele zapytań - spróbuj ponownie później.");
      }
    });
  }

  if (networkError) {
    // console.error(`[Network error]: ${networkError}`);
    if (networkError.message.includes("Too Many Requests")) {
      alert("Wysłano zbyt wiele zapytań - spróbuj ponownie później.");
    }
  }
});
const link = from([errorLink, removeTypenameFromMutationLink, authLink, uploadLink]);

export const client = new ApolloClient({
  name: APP_CLIENT_NAME,
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          company: {
            keyArgs: false,
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
    },
  }),
});
