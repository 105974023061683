import React from "react";
import { useNavigate } from "react-router-dom";

import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { getLoginPath } from "src/common/router/routerPaths";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-screen w-screen items-center justify-center flex-col">
      <h1>404 - Page Not Found</h1>
      <p>Strona której szukasz nie istnieje</p>
      <AppButton
        customClass="max-w-[25%] mt-4 rounded-[8px]"
        textClass="text-white"
        color={ButtonColorTypes.PRIMARY}
        title="Przejdź do loginu"
        onClick={() => navigate(getLoginPath(), { replace: true })}
      />
    </div>
  );
};

export default NotFound;
