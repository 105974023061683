import React, { useEffect, useState } from "react";

import { InvoiceGenres, InvoiceTypes } from "src/api/graphql/types-and-hooks";

import {
  CarGenreOptions,
  InvoiceGenresOptions,
  InvoiceTypesOptions,
  TaxGenreOptions,
} from "src/pages/invoice/addSingleInvoice/addSingleInvoice.consts";
import InvoiceOptionsModal from "src/pages/invoice/modals/InvoiceOptionsModal";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import TaxForm from "../forms/TaxForm";
import LoanForm from "../forms/LoanForm";
import InvoiceForm from "../forms/InvoiceForm";

type Props = {
  onGoBack: () => void;
  startAccountValuesDate: Date;
};

const AddSingleInvoice: React.FC<Props> = ({ onGoBack, startAccountValuesDate }) => {
  const [step, setStep] = useState(1);
  const [invoiceType, setInvoiceType] = useState(null);
  const [invoiceGenre, setInvoiceGenre] = useState(null);
  const [invoiceSubtype, setInvoiceSubtype] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [billData, setBillData] = useState(null);

  const currentUser = useCurrentUser();

  useEffect(() => {
    if (currentUser) {
      const { company } = currentUser;
      setBillData({
        address: company?.address,
        name: company?.name,
        city: company?.city,
        postcode: company?.postalCode,
        country: company?.country,
        companyId: company?.companyId,
      });
    }
  }, [currentUser]);

  const handleInvoiceTypeConfirm = () => {
    if (invoiceType === InvoiceTypes.Income) {
      setInvoiceGenre(InvoiceGenres.Invoice);
      setShowUploadModal(true);
      setStep(3);
    } else {
      setStep(2);
    }
  };

  const handleInvoiceGenreConfirm = () => {
    setStep(3);
    if (invoiceGenre === InvoiceGenres.Invoice) {
      setShowUploadModal(true);
    }
  };

  const handleInvoiceSubtypeConfirm = (showUploadModal = false) => {
    if (showUploadModal) setShowUploadModal(true);
    setStep(4);
  };

  const showInvoiceForm =
    invoiceType === InvoiceTypes.Income || invoiceGenre === InvoiceGenres.Invoice || invoiceGenre === InvoiceGenres.Car;

  return (
    <>
      {step === 1 && (
        <InvoiceOptionsModal
          onGoBack={onGoBack}
          onChange={(val) => setInvoiceType(val)}
          onConfirm={handleInvoiceTypeConfirm}
          options={InvoiceTypesOptions}
        />
      )}
      {step === 2 && (
        <InvoiceOptionsModal
          onGoBack={() => setStep(1)}
          onChange={(val) => setInvoiceGenre(val)}
          onConfirm={handleInvoiceGenreConfirm}
          options={InvoiceGenresOptions}
          subtitle="Select-document-genre-and-specify-cost-deduction"
        />
      )}
      {step === 3 && [InvoiceGenres.Car, InvoiceGenres.Tax].includes(invoiceGenre) && (
        <InvoiceOptionsModal
          onGoBack={() => setStep(2)}
          onChange={(val) => setInvoiceSubtype(val)}
          onConfirm={() => handleInvoiceSubtypeConfirm(invoiceGenre === InvoiceGenres.Car)}
          options={invoiceGenre === InvoiceGenres.Car ? CarGenreOptions : TaxGenreOptions}
        />
      )}
      {showInvoiceForm && (
        <InvoiceForm
          formValues={{
            type: invoiceType,
            genre: invoiceGenre,
            subtype: invoiceSubtype,
            ...(invoiceType === InvoiceTypes.Income
              ? {
                  billFrom: billData,
                }
              : { billTo: billData }),
          }}
          startAccountValuesDate={startAccountValuesDate}
          showUploadModal={showUploadModal}
          onGoBack={() => {
            setShowUploadModal(false);
            setStep((prevState) => (invoiceType === InvoiceTypes.Income ? 1 : prevState - 1));
          }}
        />
      )}
      {invoiceGenre === InvoiceGenres.Loan && (
        <LoanForm
          formValues={{ type: invoiceType, genre: invoiceGenre }}
          startAccountValuesDate={startAccountValuesDate}
        />
      )}
      {invoiceGenre === InvoiceGenres.Tax && (
        <TaxForm
          formValues={{ type: invoiceType, genre: invoiceGenre, subtype: invoiceSubtype }}
          startAccountValuesDate={startAccountValuesDate}
        />
      )}
    </>
  );
};

export default AddSingleInvoice;
