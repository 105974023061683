export const parsePostCode = (value: string) => {
  value = value?.replace(/[^0-9]/g, "");
  if (value?.length > 2) {
    value = value.slice(0, 2) + "-" + value.slice(2);
  }
  if (value?.length > 6) {
    value = value.slice(0, 6);
  }
  return value;
};
