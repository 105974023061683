import React from "react";
import { useTranslation } from "react-i18next";

import AppButton, { ButtonColorTypes } from "../form/AppButton";

type Props = {
  show: boolean;
  loading?: boolean;
  children: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  cancelTextClass?: string;
  confirmTextClass?: string;
  onCancel: () => void;
  onConfirm: () => void;
  onlyClose?: boolean;
};

const Modal: React.FC<Props> = ({
  show,
  loading,
  children,
  cancelText = "Cancel",
  confirmText = "Confirm",
  cancelTextClass,
  confirmTextClass,
  onCancel,
  onConfirm,
  onlyClose = false,
}) => {
  const { t } = useTranslation();

  if (!show) return null;
  return (
    <div className="absolute">
      <div className="relative z-20">
        <div className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center">
            <div className="relative bg-gradient-to-r to-[#202B4F] from-[#352837] transform rounded-[42px] text-left shadow-xl transition-all p-11">
              {children}
              <div className="mt-10 flex flex-row space-x-4">
                {!onlyClose ? (
                  <div className="basis-1/2">
                    <AppButton
                      title={t(cancelText)}
                      color={ButtonColorTypes.SECONDARY}
                      onClick={onCancel}
                      customClass="rounded-[12px]"
                      textClass={cancelTextClass}
                    />
                  </div>
                ) : undefined}
                <div className={`${!onlyClose ? "basis-1/2" : "w-full"}`}>
                  <AppButton
                    title={t(confirmText)}
                    color={ButtonColorTypes.PRIMARY}
                    onClick={onConfirm}
                    customClass="rounded-[12px]"
                    isLoading={loading}
                    textClass={confirmTextClass}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
