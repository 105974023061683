import React from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";

import Login from "./pages/login/Login";
import Cockpit from "./pages/cockpit/Cockpit";
import HomeWrapper from "./pages/home/Home";

import {
  getLoginPath,
  getBudgetsPath,
  getCockpitPath,
  getFeedbackPath,
  getRegisterPath,
  getInvoicesPath,
  getInvoiceNewPath,
  getInvoiceEditPath,
  getRegisterUserPath,
  getResetPasswordPath,
  getVerifyAccountPath,
  getBudgetDetailsPath,
  getSelectPackagePath,
  getSettingsUsersPath,
  getSettingsOrdersPath,
  getForgotPasswordPath,
  getInvoiceDetailsPath,
  getSettingsAccountPath,
  getPaymentsCalendarPath,
  getLiquidityAnalysisPath,
  getSelectSubscriptionPath,
  getChangeSubscriptionPath,
  getChangePaymentMethodPath,
  getSettingsIntegrationsPath,
  getFinancialDetailsTaxesPath,
  getFinancialDetailsLegalFormPath,
  getFinancialDetailsCarsLeasingPath,
  getFinancialDetailsStartupDataPath,
} from "./common/router/routerPaths";
import Cars from "./pages/financialDetails/Cars";
import Users from "./pages/settings/Users";
import Taxes from "./pages/financialDetails/Taxes";
import Orders from "./pages/settings/Orders";
import Budgets from "./pages/budget/Budgets";
import Account from "./pages/settings/Account";
import Packages from "./pages/packageBuy/Packages";
import Feedback from "./pages/feedback/Feedback";
import Register from "./pages/register/Register";
import Invoices from "./pages/invoice/Invoices";
import LegalForm from "./pages/financialDetails/LegalForm";
import InvoiceEdit from "./pages/invoice/InvoiceEdit";
import StartupData from "./pages/financialDetails/StartupData";
import RegisterUser from "./pages/registerUser/RegisterUser";
import Integrations from "./pages/settings/Integrations";
import VerifyAccount from "./pages/verifyAccount/VerifyAccount";
import BudgetDetails from "./pages/budget/BudgetDetails";
import CanAddInvoice from "./pages/invoice/CanAddInvoice";
import Subscriptions from "./pages/subscriptionBuy/Subscriptions";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import ForgotPassword from "./pages/resetPassword/ForgotPassword";
import InvoiceDetails from "./pages/invoice/InvoiceDetails";
import PaymentCalendar from "./pages/paymentCalendar/PaymentCalendar";
import LiquidityAnalysis from "./pages/liquidityAnalysis/LiquidityAnalysis";
import SubscriptionChange from "./pages/subscriptionChange/SubscriptionChange";
import ChangePaymentMethod from "./pages/paymentMethod/ChangePaymentMethod";

import NotFound from "src/common/router/NotFound";

import { useCurrentUser } from "./common/AuthProvider/authProvider.hooks";
import { UserPermissionResourcesEnum, UserRoles } from "./api/graphql/types-and-hooks";

function Root() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={getCockpitPath()} />} />
        <Route element={<Register />} path={getRegisterPath()} />
        <Route element={<RegisterUser />} path={getRegisterUserPath()} />
        <Route element={<VerifyAccount />} path={getVerifyAccountPath()} />
        <Route element={<Login />} path={getLoginPath()} />
        <Route element={<ForgotPassword />} path={getForgotPasswordPath()} />
        <Route element={<ResetPassword />} path={getResetPasswordPath()} />
        <Route element={<Subscriptions />} path={getSelectSubscriptionPath()} />
        <Route
          element={
            <RequireAuth>
              <SubscriptionChange />
            </RequireAuth>
          }
          path={getChangeSubscriptionPath()}
        />
        <Route
          element={
            <RequireAuth>
              <ChangePaymentMethod />
            </RequireAuth>
          }
          path={getChangePaymentMethodPath()}
        />
        <Route
          element={
            <RequireAuth>
              <Packages />
            </RequireAuth>
          }
          path={getSelectPackagePath()}
        />
        <Route element={<HomeWrapper />}>
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.Cockpit}>
                  <Cockpit />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getCockpitPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.Transactions}>
                  <Invoices />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getInvoicesPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.Transactions}>
                  <Invoices />
                </CheckPermissions>
              </RequireAuth>
            }
            path={`${getInvoicesPath()}/:page`}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.Transactions}>
                  <InvoiceDetails />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getInvoiceDetailsPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.Transactions}>
                  <CanAddInvoice />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getInvoiceNewPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.Transactions}>
                  <InvoiceEdit />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getInvoiceEditPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.Account}>
                  <Account />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getSettingsAccountPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.Account}>
                  <Orders />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getSettingsOrdersPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.Account}>
                  <Orders />
                </CheckPermissions>
              </RequireAuth>
            }
            path={`${getSettingsOrdersPath()}/:page`}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.Account}>
                  <Users />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getSettingsUsersPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.Account}>
                  <Integrations />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getSettingsIntegrationsPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.PaymentsCalendar}>
                  <PaymentCalendar />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getPaymentsCalendarPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.LiquidityAnalysis}>
                  <LiquidityAnalysis />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getLiquidityAnalysisPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.Budget}>
                  <Budgets />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getBudgetsPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.Budget}>
                  <BudgetDetails />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getBudgetDetailsPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.Budget}>
                  <BudgetDetails />
                </CheckPermissions>
              </RequireAuth>
            }
            path={`${getBudgetDetailsPath()}/:page`}
          />
          <Route
            element={
              <RequireAuth>
                <Feedback />
              </RequireAuth>
            }
            path={getFeedbackPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.FinancialDetails}>
                  <LegalForm />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getFinancialDetailsLegalFormPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.FinancialDetails}>
                  <Taxes />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getFinancialDetailsTaxesPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.FinancialDetails}>
                  <Cars />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getFinancialDetailsCarsLeasingPath()}
          />
          <Route
            element={
              <RequireAuth>
                <CheckPermissions resource={UserPermissionResourcesEnum.FinancialDetails}>
                  <StartupData />
                </CheckPermissions>
              </RequireAuth>
            }
            path={getFinancialDetailsStartupDataPath()}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const isLoggedUser = localStorage.getItem("accessToken");
  const currentUser = useCurrentUser();

  if (isLoggedUser && currentUser && !currentUser?.subscribed) {
    return <Navigate to={getSelectSubscriptionPath()} replace />;
  }

  if (!isLoggedUser) {
    return <Navigate to={getLoginPath()} replace />;
  }

  return children;
}

function CheckPermissions({ children, resource }: { children: JSX.Element; resource: UserPermissionResourcesEnum }) {
  const currentUser = useCurrentUser();
  const permissionsEnabled = currentUser?.company?.subscription?.subscription?.permissionsEnabled;

  // if user is admin or subscription does not have permissions enabled, return children
  if (currentUser?.role === UserRoles.Admin || !permissionsEnabled) return children;

  const userPermissions = currentUser?.permissions;
  const hasPermission = userPermissions?.some((permission) => permission.resource === resource);

  if (!hasPermission) {
    return <Navigate to={getCockpitPath()} replace />;
  }

  return children;
}

export default Root;
