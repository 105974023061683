import {
  useCompanyCarsLazyQuery,
  useCreateInvoiceMutation,
  useReadInvoiceDataLazyQuery,
  useUpsertContractorMutation,
} from "src/api/graphql/generated/gql-hooks";
import { CompanyCar, CreateInvoiceInput, InvoiceTypes, UpsertContractorInput } from "src/api/graphql/types-and-hooks";

export const useCreateInvoice = () => {
  const { handleUpsertContractor } = useUpsertContractor();
  const [handleCreateInvoice] = useCreateInvoiceMutation({
    onCompleted: ({ createInvoice }) => {
      const contractor = createInvoice.type === InvoiceTypes.Cost ? createInvoice.billFrom : createInvoice.billTo;
      if (contractor?.companyId && contractor?.name) {
        const { __typename, ...rest } = contractor;
        handleUpsertContractor(rest as UpsertContractorInput);
      }
    },
  });

  return {
    handleCreateInvoice: async (createInvoiceInput: CreateInvoiceInput) => {
      await handleCreateInvoice({ variables: { createInvoiceInput } });
    },
  };
};

export const useReadDataFromInvoice = () => {
  const [readDataFromInvoice] = useReadInvoiceDataLazyQuery({
    fetchPolicy: "no-cache",
  });

  return {
    handleReadDataFromInvoice: async (file: Blob) => {
      const res = await readDataFromInvoice({ variables: { file } });

      if (res?.data?.readInvoiceData) {
        const { __typename, ...rest } = res.data.readInvoiceData;
        return rest;
      }

      return null;
    },
  };
};

export const useCompanyCars = (companyId: string) => {
  const [getCompanyCars, { loading, data }] = useCompanyCarsLazyQuery({});

  const cars = (data?.company?.cars || []) as CompanyCar[];

  return {
    getCompanyCars: () => {
      getCompanyCars({ variables: { companyId } });
    },
    isCompanyCarsLoading: loading,
    cars: cars.filter((car) => !car.deletedAt),
  };
};

export const useUpsertContractor = () => {
  const [upsertContractor] = useUpsertContractorMutation();

  return {
    handleUpsertContractor: (upsertContractorInput: UpsertContractorInput) => {
      upsertContractor({ variables: { upsertContractorInput } });
    },
  };
};
