import { InvoiceGenres, InvoiceSubtypes, InvoiceTypes } from "src/api/graphql/types-and-hooks";

export const CarGenreOptions = [
  { value: InvoiceSubtypes.Fuel, label: "Fuel" },
  { value: InvoiceSubtypes.Insurance, label: "Insurance" },
  { value: InvoiceSubtypes.Service, label: "Service" },
  { value: InvoiceSubtypes.Leasing, label: "Leasing" },
];

export const InvoicesGenresOptions = [
  {
    value: InvoiceGenres.Invoice,
    label: "Invoice-cost",
  },
  {
    value: InvoiceGenres.Car,
    label: "Car",
  },
];

export const InvoiceTypesOptions = [
  { value: InvoiceTypes.Income, label: "Przychód" },
  { value: InvoiceTypes.Cost, label: "Koszt" },
];
