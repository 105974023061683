import React from "react";
import { useTranslation } from "react-i18next";

import { useToastError } from "src/common/hooks/useToast";

import AppButton, { ButtonColorTypes } from "./AppButton";

type Props = {
  customClass?: string;
  onClick: (files: File[]) => void;
  loading?: boolean;
  textClass?: string;
  fileType?: string;
  isMultiple?: boolean;
  disabled?: boolean;
};

const MAX_FILE_SIZE = 3 * 1024 * 1024;

const AppUploadInput: React.FC<Props> = ({
  customClass,
  onClick,
  loading,
  textClass,
  fileType = "application/pdf,image/*",
  isMultiple = false,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        ref={inputRef}
        accept={fileType}
        type="file"
        required
        className="hidden"
        multiple={isMultiple}
        onChange={({ target: { validity, files } }) => {
          if (validity.valid) {
            if (files.length > 10) {
              useToastError(t("Max-files-number-exceeded"));
              return;
            }

            const validFiles = Array.from(files || []).filter((file) => file.size <= MAX_FILE_SIZE);
            if (validFiles.length !== files.length) {
              useToastError(t("File-size-exceeded"));
              return;
            }

            if (validFiles.length > 0) onClick(validFiles);
          }
        }}
        disabled={disabled}
      />
      <AppButton
        title={t("Send-file")}
        onClick={() => inputRef.current?.click()}
        color={ButtonColorTypes.PRIMARY}
        customClass={customClass}
        isLoading={loading}
        textClass={textClass}
      />
    </>
  );
};
export default AppUploadInput;
