import * as pdfjsLib from "pdfjs-dist";
import { convertBlobToBuffer } from "src/utils/convertBlobToBuffer";

export const useCreateThumbnail = () => {
  const createThumbnail = async (file: Blob, htmlElementId: string) => {
    try {
      const div: HTMLElement = document.getElementById(htmlElementId);
      if (!div) {
        console.error("Element not found");
        return;
      }

      if (file.type === "application/pdf") {
        pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js`;
        const buffer = await convertBlobToBuffer(file);
        const pdfDoc = await pdfjsLib.getDocument({ data: buffer }).promise;
        const page: pdfjsLib.PDFPageProxy = await pdfDoc.getPage(1);

        const viewport: pdfjsLib.PDFPageViewport = page.getViewport({ scale: 1 });
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        const context: CanvasRenderingContext2D = canvas.getContext("2d");
        canvas.width = 140;
        canvas.height = 190;
        const scale: number = Math.min(canvas.width / viewport.width, canvas.height / viewport.height);
        const renderContext: pdfjsLib.PDFRenderParams = {
          canvasContext: context,
          viewport: page.getViewport({ scale }),
        };
        page.render(renderContext);

        const isAlreadyRendered = document.getElementById(htmlElementId)?.querySelector("canvas");
        if (isAlreadyRendered) {
          isAlreadyRendered.remove();
        }

        div.appendChild(canvas);
      } else if (file.type.startsWith("image/")) {
        const img = new Image();
        img.onload = () => {
          const canvas: HTMLCanvasElement = document.createElement("canvas");
          const context: CanvasRenderingContext2D = canvas.getContext("2d");
          canvas.width = 140;
          canvas.height = 190;
          const scale: number = Math.min(canvas.width / img.width, canvas.height / img.height);
          const width = img.width * scale;
          const height = img.height * scale;
          context.drawImage(img, 0, 0, width, height);

          const isAlreadyRendered = document.getElementById(htmlElementId)?.querySelector("canvas");
          if (isAlreadyRendered) {
            isAlreadyRendered.remove();
          }
          div.appendChild(canvas);
        };
        img.src = URL.createObjectURL(file);
      } else {
        console.error("Unsupported file type");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { createThumbnail };
};
