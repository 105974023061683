import React, { useEffect, useState } from "react";

import UploadFile from "src/components/ui/UploadFile";

import { useCompanyOcrPagesLeft } from "src/pages/invoice/invoice.hooks";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import { getPdfPagesCount } from "src/utils/getPdfPagesCount";

type Props = {
  onUseOcr: (useOcr: boolean) => void;
  onCancel: () => void;
  onConfirm: (files: File[]) => void;
  isMultiple?: boolean;
};

const InvoicesUploadModal: React.FC<Props> = ({ onUseOcr, isMultiple, onCancel, onConfirm }) => {
  const [uploadedInvoices, setUploadedInvoices] = useState<File[]>([]);
  const [isOcrDisabled, setIsOcrDisabled] = useState(false);
  const [filePagesCount, setFilePagesCount] = useState(0);

  const currentUser = useCurrentUser();
  const { ocrPagesLeft, companyOcrPagesLeftLoading } = useCompanyOcrPagesLeft(currentUser?.company?._id);

  useEffect(() => {
    setIsOcrDisabled(!uploadedInvoices.length || !ocrPagesLeft || filePagesCount > ocrPagesLeft);
  }, [uploadedInvoices, ocrPagesLeft, filePagesCount]);

  const handleFilePagesCount = async (file: File) => {
    const pagesCount = file.type === "application/pdf" ? await getPdfPagesCount(file) : 1;
    setFilePagesCount(pagesCount);
  };

  const onFileUpload = (files: File[]) => {
    if (isMultiple) {
      setUploadedInvoices((prevState) => [...prevState, ...files]);
    } else {
      setUploadedInvoices(files);
      handleFilePagesCount(files[0]);
    }
  };

  const onDropFile = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (isMultiple && uploadedInvoices.length >= 10) return;

    const file = e.dataTransfer?.files?.[0];
    if (!file) return;

    if (isMultiple) {
      setUploadedInvoices((prevState) => [...prevState, file]);
    } else {
      setUploadedInvoices([file]);
    }
  };

  return (
    <UploadFile
      show
      ocrPagesLoading={companyOcrPagesLeftLoading}
      ocrPagesLeft={ocrPagesLeft}
      fileNames={uploadedInvoices.map((file) => file.name)}
      isOcrDisabled={isOcrDisabled || companyOcrPagesLeftLoading}
      onCancel={onCancel}
      onConfirm={() => onConfirm(uploadedInvoices)}
      onUseOcr={onUseOcr}
      onUploadFile={(files) => onFileUpload(files)}
      onDropFile={onDropFile}
      isMultiple={isMultiple}
    />
  );
};

export default React.memo(InvoicesUploadModal);
