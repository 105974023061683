import React from "react";
import { useTranslation } from "react-i18next";

import Spinner from "src/components/ui/Spinner";
import AppUploadInput from "src/components/form/AppUploadInput";

import UploadIcon from "src/assets/images/upload.svg";

import Text from "./Text";
import Modal from "./Modal";

type Props = {
  show: boolean;
  fileNames: string[];
  loading?: boolean;
  isOcrDisabled: boolean;
  ocrPagesLeft: number;
  ocrPagesLoading?: boolean;
  onCancel: () => void;
  onUseOcr: (useOcr: boolean) => void;
  onConfirm: () => void;
  onDropFile: (e: React.DragEvent<HTMLDivElement>) => void;
  onUploadFile: (files: File[]) => void;
  isMultiple?: boolean;
};

const UploadFile: React.FC<Props> = ({
  show = false,
  fileNames,
  ocrPagesLeft,
  loading = false,
  isOcrDisabled,
  onCancel,
  onUseOcr,
  onConfirm,
  onDropFile,
  onUploadFile,
  isMultiple = false,
  ocrPagesLoading = false,
}) => {
  const { t } = useTranslation();

  const renderFileNames = () => {
    if (!fileNames.length) {
      return <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">{t("Drag-and-drop-info")}</Text>;
    } else {
      return fileNames.map((fileName, i) => (
        <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary" key={`${fileName}-${i}`}>
          {fileName}
        </Text>
      ));
    }
  };

  return (
    <Modal
      show={show}
      loading={loading}
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelText="Previous"
      confirmText="Next"
      confirmTextClass="text-14 text-white"
      cancelTextClass="text-14 text-text-secondary"
    >
      <Text className="2xl:text-42 xl:text-36 text-32 text-white font-sf-pro-medium">{t("Send-document")}</Text>
      <div
        className="border border-text-secondary border-dashed rounded-[20px] 2xl:mt-10 xl:mt-8 mt-6 p-6 flex justify-center"
        onDrop={() => fileNames.length < 10 && onDropFile}
        onDragOver={(e) => e.preventDefault()}
      >
        <div className="flex flex-col space-y-3 items-center w-full">
          <img src={UploadIcon} />
          <div className="flex flex-col items-center">{renderFileNames()}</div>
          <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">- {t("Or")} -</Text>
          <div className="w-full">
            <AppUploadInput
              onClick={onUploadFile}
              customClass="rounded-[12px]"
              textClass="text-white"
              isMultiple={isMultiple}
              disabled={fileNames.length >= 10}
            />
          </div>
          <div className="flex flex-row space-x-4">
            <input
              className="form-checkbox 2xl:w-[18px] xl:w-[16px] w-[14px] 2xl:h-[18px] xl:h-[16px] h-[14px] rounded-[4px] cursor-pointer checked:bg-blue ring-0 focus:ring-0 border border-[#DFE5F1]"
              type="checkbox"
              onChange={(e) => onUseOcr(e.target.checked)}
              disabled={isOcrDisabled}
            />
            <div className="flex flex-row">
              <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">
                {t("Use-ocr-label")} ({t("Left")} &nbsp;{" "}
              </Text>
              {ocrPagesLoading ? (
                <Spinner />
              ) : (
                <Text className="2xl:text-14 xl:text-12 text-10 font-sf-pro-bold text-white">
                  {ocrPagesLeft || 0} &nbsp;
                </Text>
              )}
              <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">{t("Pages")})</Text>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadFile;
