import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";

type Option = {
  value: string;
  label: string;
};

type AppDebouncedSelectProps = {
  options: Option[];
  onSearch: (query: string) => void;
  onSelect: (value: Option) => void;
  isLoading?: boolean;
  disabled?: boolean;
  placeholder?: string;
};

const AppDebouncedSelect: React.FC<AppDebouncedSelectProps> = ({
  options,
  onSearch,
  isLoading = false,
  onSelect,
  disabled = false,
  placeholder = "Wyszukaj",
}) => {
  const [query, setQuery] = useState("");

  const { t } = useTranslation();

  const debouncedSearch = useCallback(
    debounce((searchQuery: string) => {
      if (searchQuery.length) onSearch(searchQuery);
    }, 1000),
    [],
  );

  useEffect(() => {
    debouncedSearch(query);
  }, [query, debouncedSearch]);

  return (
    <Select
      isLoading={isLoading}
      value={null}
      onInputChange={setQuery}
      onChange={onSelect}
      options={options}
      isDisabled={disabled}
      placeholder={placeholder}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          neutral50: "#8E98A7",
          primary: "#233145",
        },
      })}
      noOptionsMessage={() => t("No-results-found")}
      components={{
        IndicatorSeparator: () => null,
      }}
      classNames={{
        valueContainer: () => "2xl:text-16 xl:text-14 text-10 font-sf-pro-regular",
      }}
      styles={{
        control: (base) => ({
          ...base,
          backgroundColor: "white",
          border: `1px solid #DFE5F1`,
          borderRadius: "10px",
          minHeight: "28px",
          height: "28px",
          "&:hover": {
            outline: "none",
          },
          "@media (min-width: 1280px)": {
            minHeight: "36px",
            height: "36px",
          },
          "@media (min-width: 1536px)": {
            minHeight: "40px",
            height: "40px",
          },
          opacity: disabled ? 0.5 : 1,
        }),
        placeholder: (styles) => {
          return {
            ...styles,
            fontWeight: 800,
          };
        },
        singleValue: (styles) => {
          return {
            ...styles,
            color: "#233145",
          };
        },
        option: (styles, { isSelected }) => {
          return {
            ...styles,
            cursor: "pointer",
            ":hover": {
              backgroundColor: "#F4F6F9",
            },
            fontSize: "8px",
            "@media (min-width: 1280px)": {
              fontSize: "12px",
            },
            "@media (min-width: 1536px)": {
              fontSize: "16px",
            },
          };
        },
        groupHeading: (base) => ({
          ...base,
          marginLeft: "-10px",
        }),
        group: (base) => ({
          ...base,
          marginLeft: "10px",
        }),
        dropdownIndicator: (base) => ({
          ...base,
          color: "hsl(0, 0%, 80%)",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          minHeight: "28px",
          height: "28px",
          "@media (min-width: 1280px)": {
            minHeight: "36px",
            height: "36px",
          },
          "@media (min-width: 1536px)": {
            minHeight: "40px",
            height: "40px",
          },
        }),
      }}
    />
  );
};

export default AppDebouncedSelect;
