import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type AddCarToCompanyInput = {
  financingForm: CarFinancingFormTypes;
  formOfUse: CarUseFormTypes;
  isElectric?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  subtotal: Scalars['Float']['input'];
  total: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export type AddDocumentsToCategoryInput = {
  categoryId: Scalars['ID']['input'];
  documentIds: Array<Scalars['String']['input']>;
  type: BudgetCategoryTypes;
};

export type AddSingleTransactionInput = {
  budgetId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
  date: Scalars['DateTime']['input'];
  type: BudgetCategoryTypes;
  value: Scalars['Float']['input'];
};

export type AddUserToCompanyInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type AddUserToCompanyResponse = {
  _id: Scalars['ID']['output'];
};

export type BaseCreateResponse = {
  id: Scalars['ID']['output'];
};

export type BaseDeleteDocumentInput = {
  id: Scalars['ID']['input'];
};

export type BaseDeleteResponse = {
  success: Scalars['Boolean']['output'];
};

export type BaseSuccessResponse = {
  success: Scalars['Boolean']['output'];
};

export type BillFrom = {
  name: Scalars['String']['output'];
};

export type BillTo = {
  name: Scalars['String']['output'];
};

export type BillToFilterInput = {
  companyId?: InputMaybe<FilterOperators>;
  name?: InputMaybe<FilterOperators>;
};

export type Budget = {
  _id: Scalars['ID']['output'];
  company: Company;
  costCategoriesTree?: Maybe<Array<BudgetCategoryTree>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dateFrom?: Maybe<Scalars['DateTime']['output']>;
  dateTo?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  incomeCategoriesTree?: Maybe<Array<BudgetCategoryTree>>;
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BudgetCategoriesFilterOptionsInput = {
  budget?: InputMaybe<FilterOperators>;
  createdAt?: InputMaybe<FilterOperators>;
  type?: InputMaybe<FilterOperators>;
};

export type BudgetCategoriesList = {
  documents: Array<BudgetCategory>;
  total: Scalars['Float']['output'];
};

export type BudgetCategoriesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
};

export type BudgetCategory = {
  _id: Scalars['ID']['output'];
  budget?: Maybe<Budget>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  isLeaf: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<BudgetCategory>;
  type: BudgetCategoryTypes;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BudgetCategoryTree = {
  category: BudgetCategory;
  children: Array<BudgetCategoryTree>;
  months?: Maybe<Array<BudgetMonth>>;
  value: Scalars['Float']['output'];
};

export enum BudgetCategoryTypes {
  Cost = 'COST',
  Income = 'INCOME'
}

export type BudgetCostsAndIncomesPerMonth = {
  isPrognosis: Scalars['Boolean']['output'];
  month: Scalars['String']['output'];
  totalCosts: Scalars['Float']['output'];
  totalIncomes: Scalars['Float']['output'];
};

export type BudgetDocument = {
  _id: Scalars['ID']['output'];
  billFrom?: Maybe<InvoiceCompany>;
  billTo?: Maybe<InvoiceCompany>;
  budget: Scalars['ID']['output'];
  category?: Maybe<BudgetCategory>;
  company: Company;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency: Currency;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  filePath?: Maybe<Scalars['String']['output']>;
  genre: InvoiceGenres;
  invoiceNumber: Scalars['String']['output'];
  issueDate?: Maybe<Scalars['DateTime']['output']>;
  leftToPay?: Maybe<Scalars['Float']['output']>;
  paymentDue?: Maybe<Scalars['DateTime']['output']>;
  paymentStatus?: Maybe<PaymentStatus>;
  payments: Array<InvoicePayment>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  subtype?: Maybe<InvoiceSubtypes>;
  total?: Maybe<Scalars['Float']['output']>;
  type: InvoiceTypes;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vat?: Maybe<Scalars['Float']['output']>;
};

export type BudgetDocumentsFilterOptionsInput = {
  billTo?: InputMaybe<BillToFilterInput>;
  budget?: InputMaybe<FilterOperators>;
  category?: InputMaybe<FilterOperators>;
  company?: InputMaybe<FilterOperators>;
  description?: InputMaybe<FilterOperators>;
  invoiceNumber?: InputMaybe<FilterOperators>;
  paymentDue?: InputMaybe<FilterOperators>;
  paymentStatus?: InputMaybe<FilterOperators>;
  type?: InputMaybe<FilterOperators>;
};

export type BudgetDocumentsList = {
  documents: Array<BudgetDocument>;
  total: Scalars['Float']['output'];
};

export type BudgetDocumentsSortOptionsInput = {
  paymentDue?: InputMaybe<SortOptions>;
};

export type BudgetMonth = {
  month: Scalars['String']['output'];
  value: Scalars['Float']['output'];
  weeks: Array<BudgetWeek>;
};

export type BudgetResponse = {
  _id?: Maybe<Scalars['ID']['output']>;
  company?: Maybe<Company>;
  costCategoriesTree?: Maybe<Array<BudgetCategoryTree>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dateFrom?: Maybe<Scalars['DateTime']['output']>;
  dateTo?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  incomeCategoriesTree?: Maybe<Array<BudgetCategoryTree>>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BudgetWeek = {
  value: Scalars['Float']['output'];
  week: Scalars['Float']['output'];
};

export type BudgetsFilterOptionsInput = {
  company?: InputMaybe<FilterOperators>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<FilterOperators>;
};

export type BudgetsList = {
  documents: Array<Budget>;
  total: Scalars['Float']['output'];
};

export type BudgetsSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
};

export enum CarFinancingFormTypes {
  LongTermRental = 'LONG_TERM_RENTAL',
  OperationalLeasing = 'OPERATIONAL_LEASING'
}

export enum CarUseFormTypes {
  Company = 'COMPANY',
  Mixed = 'MIXED'
}

export type CheckLiquidityAgainstFinancialMinimumOptionsInput = {
  filter?: InputMaybe<LiquidityAgainstFinancialMinimumOptionsInput>;
};

export type CheckOrderStatusResponse = {
  status: OrderStatus;
};

export enum CitTaxAmountTypes {
  Nine = 'NINE',
  Nineteen = 'NINETEEN',
  Zero = 'ZERO'
}

export type CompaniesFilterOptionsInput = {
  companyId?: InputMaybe<FilterOperators>;
  createdAt?: InputMaybe<FilterOperators>;
  name?: InputMaybe<FilterOperators>;
  subscribedFrom?: InputMaybe<FilterOperators>;
  subscribedTo?: InputMaybe<FilterOperators>;
  subscription?: InputMaybe<FilterOperators>;
};

export type CompaniesList = {
  documents: Array<Company>;
  total: Scalars['Float']['output'];
};

export type CompaniesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  name?: InputMaybe<SortOptions>;
};

export type Company = {
  _id: Scalars['ID']['output'];
  accountBalance?: Maybe<Scalars['Float']['output']>;
  additionalOcrPages: Scalars['Float']['output'];
  address: Scalars['String']['output'];
  cars: Array<CompanyCar>;
  city: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  integrationData?: Maybe<CompanyIntegrationData>;
  isVerified: Scalars['Boolean']['output'];
  legalForm?: Maybe<CompanyLegalFormTypes>;
  name: Scalars['String']['output'];
  newCompany: Scalars['Boolean']['output'];
  newSubscription?: Maybe<Subscription>;
  ocrPagesLeft: Scalars['Float']['output'];
  paymentMethod?: Maybe<CompanyPaymentMethod>;
  phone: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  startAccountBalance?: Maybe<Scalars['Float']['output']>;
  startAccountValuesDate?: Maybe<Scalars['DateTime']['output']>;
  startVatAccountBalance?: Maybe<Scalars['Float']['output']>;
  subscription?: Maybe<CompanySubscription>;
  taxDetails?: Maybe<CompanyTaxDetails>;
  trialUsed?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users: Array<User>;
  vatAccountBalance?: Maybe<Scalars['Float']['output']>;
};

export type CompanyCar = {
  _id: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  financingForm: CarFinancingFormTypes;
  formOfUse: CarUseFormTypes;
  isElectric: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  subtotal: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vat: Scalars['Float']['output'];
};

export type CompanyIntegrationData = {
  apiKey: Scalars['String']['output'];
  secretKey?: Maybe<Scalars['String']['output']>;
  type: CompanyIntegrationTypes;
};

export type CompanyIntegrationDataInput = {
  apiKey: Scalars['String']['input'];
  secretKey?: InputMaybe<Scalars['String']['input']>;
  type: CompanyIntegrationTypes;
};

export enum CompanyIntegrationTypes {
  Infakt = 'INFAKT',
  Wfirma = 'WFIRMA'
}

export enum CompanyLegalFormTypes {
  GeneralPartnership = 'GENERAL_PARTNERSHIP',
  JointStockCompany = 'JOINT_STOCK_COMPANY',
  JointStockLimitedPartnership = 'JOINT_STOCK_LIMITED_PARTNERSHIP',
  LimitedLiabilityCompany = 'LIMITED_LIABILITY_COMPANY',
  LimitedPartnership = 'LIMITED_PARTNERSHIP',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP'
}

export type CompanyPaymentMethod = {
  maskedCardNumber: Scalars['String']['output'];
};

export type CompanyPaymentMethodInput = {
  maskedCardNumber: Scalars['String']['input'];
};

export type CompanySubscription = {
  dateEnd?: Maybe<Scalars['DateTime']['output']>;
  dateFrom: Scalars['DateTime']['output'];
  nextPaymentDate?: Maybe<Scalars['DateTime']['output']>;
  paymentFailures: Scalars['Float']['output'];
  subscription: Subscription;
};

export type CompanySubscriptionInput = {
  dateFrom: Scalars['DateTime']['input'];
  nextPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  paymentFailures?: InputMaybe<Scalars['Float']['input']>;
  subscription: Scalars['ID']['input'];
};

export type CompanyTaxDetails = {
  citTaxAmount?: Maybe<CitTaxAmountTypes>;
  flatRateTaxAmount?: Maybe<FlatRateTaxAmountTypes>;
  isVatPayer?: Maybe<Scalars['Boolean']['output']>;
  pitTaxType?: Maybe<PitTaxTypeTypes>;
  taxType?: Maybe<TaxTypes>;
  vatType?: Maybe<VatTaxTypes>;
};

export type CompanyTaxDetailsInput = {
  citTaxAmount?: InputMaybe<CitTaxAmountTypes>;
  flatRateTaxAmount?: InputMaybe<FlatRateTaxAmountTypes>;
  isVatPayer?: InputMaybe<Scalars['Boolean']['input']>;
  pitTaxType?: InputMaybe<PitTaxTypeTypes>;
  taxType: TaxTypes;
  vatType?: InputMaybe<VatTaxTypes>;
};

export type Contractor = {
  _id: Scalars['ID']['output'];
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContractorsFilterOptionsInput = {
  value?: InputMaybe<FilterOperators>;
};

export type CreateBudgetCategoryInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  budget: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['ID']['input']>;
  type: BudgetCategoryTypes;
};

export type CreateBudgetInput = {
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
  importExistingInvoices: Scalars['Boolean']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  withDefaultCategories: Scalars['Boolean']['input'];
};

export type CreateCompanyInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  user: Scalars['ID']['input'];
};

export type CreateInvoiceInput = {
  billFrom?: InputMaybe<InvoiceCompanyInput>;
  billTo?: InputMaybe<InvoiceCompanyInput>;
  capital?: InputMaybe<Scalars['Float']['input']>;
  car?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Currency>;
  description?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  genre: InvoiceGenres;
  interest?: InputMaybe<Scalars['Float']['input']>;
  invoiceNumber: Scalars['String']['input'];
  issueDate: Scalars['DateTime']['input'];
  leftToPay: Scalars['Float']['input'];
  paymentDue: Scalars['DateTime']['input'];
  paymentStatus: PaymentStatus;
  saleDate?: InputMaybe<Scalars['DateTime']['input']>;
  settlementPeriod?: InputMaybe<Scalars['DateTime']['input']>;
  settlementPeriodType?: InputMaybe<TaxSettlementPeriodType>;
  splitPayment?: InputMaybe<Scalars['Boolean']['input']>;
  subtotal: Scalars['Float']['input'];
  subtype?: InputMaybe<InvoiceSubtypes>;
  total: Scalars['Float']['input'];
  type: InvoiceTypes;
  vat: Scalars['Float']['input'];
};

export type CreateInvoiceResponse = {
  billFrom: InvoiceCompany;
  billTo: InvoiceCompany;
  id: Scalars['ID']['output'];
  paymentDue: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
};

export type CreateNotificationInput = {
  company: Scalars['String']['input'];
  description: Scalars['String']['input'];
  module?: InputMaybe<NotificationModuleTypes>;
  recordId?: InputMaybe<Scalars['String']['input']>;
  type: NotificationTypes;
};

export type CreateOrderInput = {
  companyEmail: Scalars['String']['input'];
  subscriptionId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type CreateOrderResponse = {
  id: Scalars['ID']['output'];
  redirectUri: Scalars['String']['output'];
};

export type CreatePackageOrderInput = {
  packageId: Scalars['ID']['input'];
};

export type CreateSubscriptionInput = {
  OCRPagesCount: Scalars['Float']['input'];
  budgetingEnabled: Scalars['Boolean']['input'];
  emailNotificationsEnabled: Scalars['Boolean']['input'];
  maxUsersCount: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  optionsExcluded: Array<Scalars['String']['input']>;
  optionsIncluded: Array<Scalars['String']['input']>;
  permissionsEnabled: Scalars['Boolean']['input'];
  subtotal: Scalars['Float']['input'];
  total: Scalars['Float']['input'];
  trialSubtotal: Scalars['Float']['input'];
  trialTotal: Scalars['Float']['input'];
  trialVat: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export type CreateSubscriptionResponse = {
  id: Scalars['ID']['output'];
};

export enum Currency {
  Eur = 'EUR',
  Pln = 'PLN',
  Usd = 'USD'
}

export type DeleteBudgetCategoryInput = {
  budgetId: Scalars['ID']['input'];
  category: Scalars['String']['input'];
  categoryType: BudgetCategoryTypes;
};

export type DeleteUserFromCompanyInput = {
  companyId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type FilterOperators = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<Scalars['String']['input']>>;
  regex?: InputMaybe<Scalars['String']['input']>;
};

export type FinancialMinimum = {
  dateFrom?: Maybe<Scalars['DateTime']['output']>;
  dateTo?: Maybe<Scalars['DateTime']['output']>;
  minimum: Scalars['Float']['output'];
  type: FinancialMinimumTypes;
};

export type FinancialMinimumInput = {
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  minimum: Scalars['Float']['input'];
  type: FinancialMinimumTypes;
};

export enum FinancialMinimumTypes {
  Constant = 'CONSTANT',
  Periodic = 'PERIODIC'
}

export enum FlatRateTaxAmountTypes {
  EightPointFive = 'EIGHT_POINT_FIVE',
  Fifteen = 'FIFTEEN',
  FivePointFive = 'FIVE_POINT_FIVE',
  Fourteen = 'FOURTEEN',
  Seventeen = 'SEVENTEEN',
  Ten = 'TEN',
  Three = 'THREE',
  Twelve = 'TWELVE',
  TwelvePointFive = 'TWELVE_POINT_FIVE',
  Two = 'TWO'
}

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
};

export type ForgotPasswordResponse = {
  success: Scalars['Boolean']['output'];
};

export type GetBudgetCategoriesOptionsInput = {
  filter?: InputMaybe<BudgetCategoriesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<BudgetCategoriesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetBudgetCostsAndIncomesPerMonthResponse = {
  costsAndIncomesPerMonth: Array<BudgetCostsAndIncomesPerMonth>;
};

export type GetBudgetDocumentsOptionsInput = {
  filter?: InputMaybe<BudgetDocumentsFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<BudgetDocumentsSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetBudgetsOptionsInput = {
  filter?: InputMaybe<BudgetsFilterOptionsInput>;
  sort?: InputMaybe<BudgetsSortOptionsInput>;
};

export type GetCompaniesOptionsInput = {
  filter?: InputMaybe<CompaniesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<CompaniesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetContractorsOptionsInput = {
  filter?: InputMaybe<ContractorsFilterOptionsInput>;
};

export type GetIncomeTaxArgsOptionsInput = {
  filter?: InputMaybe<IncomeTaxFilterOptionsInput>;
};

export type GetInvoicesOptionsInput = {
  filter?: InputMaybe<InvoicesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<InvoicesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetLiquidityAnalysisOptionsInput = {
  filter?: InputMaybe<LiquidityAnalysisFilterOptionsInput>;
};

export type GetNotificationsOptionsInput = {
  filter: NotificationsFilterOptionsInput;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<NotificationsSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetOrdersOptionsInput = {
  filter?: InputMaybe<OrdersFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<OrdersSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetPackagesOptionsInput = {
  filter?: InputMaybe<PackagesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<PackagesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetSubscriptionsOptionsInput = {
  filter?: InputMaybe<SubscriptionsFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<SubscriptionsSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetTransactionsConditionOptionsInput = {
  filter?: InputMaybe<TransactionsConditionFilterOptionsInput>;
};

export type GetUsersOptionsInput = {
  filter?: InputMaybe<UsersFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<UsersSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetVatTaxArgsOptionsInput = {
  filter?: InputMaybe<VatTaxFilterOptionsInput>;
};

export type IncomeTaxFilterOptionsInput = {
  date?: InputMaybe<FilterOperators>;
};

export type IncomeTaxResponse = {
  incomeTaxValue?: Maybe<Scalars['Float']['output']>;
};

export type InovicesList = {
  documents: Array<InvoicesListDocument>;
  total: Scalars['Float']['output'];
};

export type InviteUserToCompanyInput = {
  email: Scalars['String']['input'];
  permissions?: Array<PermissionsInput>;
};

export type InviteUserToCompanyResponse = {
  success: Scalars['Boolean']['output'];
};

export type Invoice = {
  _id: Scalars['ID']['output'];
  billFrom?: Maybe<InvoiceCompany>;
  billTo?: Maybe<InvoiceCompany>;
  capital?: Maybe<Scalars['Float']['output']>;
  car?: Maybe<CompanyCar>;
  company: Company;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency: Currency;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalService?: Maybe<CompanyIntegrationTypes>;
  filePath?: Maybe<Scalars['String']['output']>;
  foreignId?: Maybe<Scalars['String']['output']>;
  genre: InvoiceGenres;
  incomeTaxValue?: Maybe<Scalars['Float']['output']>;
  interest?: Maybe<Scalars['Float']['output']>;
  invoiceNumber: Scalars['String']['output'];
  issueDate?: Maybe<Scalars['DateTime']['output']>;
  leftToPay?: Maybe<Scalars['Float']['output']>;
  paymentDate?: Maybe<Scalars['DateTime']['output']>;
  paymentDue?: Maybe<Scalars['DateTime']['output']>;
  paymentStatus?: Maybe<PaymentStatus>;
  payments: Array<InvoicePayment>;
  saleDate?: Maybe<Scalars['DateTime']['output']>;
  settlementPeriod?: Maybe<Scalars['DateTime']['output']>;
  settlementPeriodType?: Maybe<TaxSettlementPeriodType>;
  splitPayment?: Maybe<Scalars['Boolean']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  subtype?: Maybe<InvoiceSubtypes>;
  total?: Maybe<Scalars['Float']['output']>;
  type: InvoiceTypes;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  valueFromAccount?: Maybe<Scalars['Float']['output']>;
  valueFromVatAccount?: Maybe<Scalars['Float']['output']>;
  vat?: Maybe<Scalars['Float']['output']>;
  vatTaxValue?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceCompany = {
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  postcode?: Maybe<Scalars['String']['output']>;
};

export type InvoiceCompanyInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
};

export enum InvoiceGenres {
  Car = 'CAR',
  Employee = 'EMPLOYEE',
  Invoice = 'INVOICE',
  Loan = 'LOAN',
  Tax = 'TAX'
}

export type InvoicePayment = {
  capital?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  interest?: Maybe<Scalars['Float']['output']>;
  paymentDate: Scalars['DateTime']['output'];
  subtotal: Scalars['Float']['output'];
  title?: Maybe<Scalars['String']['output']>;
  total: Scalars['Float']['output'];
};

export enum InvoiceSubtypes {
  Cit = 'CIT',
  Fuel = 'FUEL',
  GroupCost = 'GROUP_COST',
  Insurance = 'INSURANCE',
  Leasing = 'LEASING',
  Pit = 'PIT',
  Service = 'SERVICE',
  UnitCost = 'UNIT_COST',
  Vat = 'VAT',
  Zus = 'ZUS'
}

export enum InvoiceTypes {
  Cost = 'COST',
  Income = 'INCOME'
}

export type InvoicesFilterOptionsInput = {
  billTo?: InputMaybe<BillToFilterInput>;
  company?: InputMaybe<FilterOperators>;
  date?: InputMaybe<FilterOperators>;
  description?: InputMaybe<FilterOperators>;
  genre?: InputMaybe<FilterOperators>;
  invoiceNumber?: InputMaybe<FilterOperators>;
  paymentDue?: InputMaybe<FilterOperators>;
  paymentStatus?: InputMaybe<FilterOperators>;
  type?: InputMaybe<FilterOperators>;
};

export type InvoicesListDocument = {
  _id: Scalars['ID']['output'];
  billFrom?: Maybe<BillFrom>;
  billTo?: Maybe<BillTo>;
  externalService?: Maybe<CompanyIntegrationTypes>;
  genre: InvoiceGenres;
  invoiceNumber: Scalars['String']['output'];
  paymentDate?: Maybe<Scalars['DateTime']['output']>;
  paymentDue?: Maybe<Scalars['DateTime']['output']>;
  paymentStatus?: Maybe<PaymentStatus>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  type: InvoiceTypes;
};

export type InvoicesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  paymentDue?: InputMaybe<SortOptions>;
};

export type LiquidityAgainstFinancialMinimumOptionsInput = {
  period?: InputMaybe<FilterOperators>;
};

export type LiquidityAnalysis = {
  d: Scalars['Float']['output'];
  date: Scalars['String']['output'];
  liquidity?: Maybe<Scalars['Float']['output']>;
};

export type LiquidityAnalysisFilterOptionsInput = {
  date?: InputMaybe<FilterOperators>;
};

export type LiquidityAnalysisResponse = {
  liquidity: Array<LiquidityAnalysis>;
  startValue: Scalars['Float']['output'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  accessToken: Scalars['String']['output'];
  user: User;
};

export type MonthlyLiquiditiesResponse = {
  documents: Array<MonthlyLiquidity>;
};

export type MonthlyLiquidity = {
  liquidity: Scalars['Float']['output'];
  month: Scalars['String']['output'];
};

export type Mutation = {
  addCarToCompany: Company;
  addDocumentsToCategory: BaseSuccessResponse;
  addSingleBudgetTransaction: Budget;
  addUserToCompany: AddUserToCompanyResponse;
  cancelCompanySubscription: BaseSuccessResponse;
  createBudget: BaseCreateResponse;
  createBudgetCategory: BaseCreateResponse;
  createCompany: Company;
  createInvoice: CreateInvoiceResponse;
  createNotification: BaseCreateResponse;
  createOrder: CreateOrderResponse;
  createPackageOrder: CreateOrderResponse;
  createSubscription: CreateSubscriptionResponse;
  deleteBudget: BaseDeleteResponse;
  deleteBudgetCategory: BaseSuccessResponse;
  deleteInvoice: BaseDeleteResponse;
  deleteNotification: BaseDeleteResponse;
  deleteUserFromCompany: BaseDeleteResponse;
  forgotPassword: ForgotPasswordResponse;
  inviteUserToCompany: InviteUserToCompanyResponse;
  login: LoginResponse;
  loginAdmin: LoginResponse;
  register: RegisterResponse;
  removeCompanyPaymentMethod: Company;
  resetPassword: ResetPasswordResponse;
  resumeCompanySubscription: BaseSuccessResponse;
  sendFeedback: BaseSuccessResponse;
  updateBudgetCategory: BudgetCategory;
  updateCategoryTreeValue: BaseSuccessResponse;
  updateCompany: Company;
  updateCompanyPaymentMethod: UpdateCompanyPaymentMethodResponse;
  updateCompanySubscription: BaseSuccessResponse;
  updateInvoice: Invoice;
  updateInvoicesPaymentStatus: BaseSuccessResponse;
  updateNotification: Notification;
  updateSubscription: Subscription;
  updateUser: User;
  uploadInvoiceToOrder: UploadInvoiceToOrderResponse;
  upsertContractor: Contractor;
  verifyAccount: VerifyAccountResponse;
};


export type MutationAddCarToCompanyArgs = {
  addCarToCompanyInput: AddCarToCompanyInput;
};


export type MutationAddDocumentsToCategoryArgs = {
  addDocumentsToCategoryInput: AddDocumentsToCategoryInput;
};


export type MutationAddSingleBudgetTransactionArgs = {
  addSingleTransactionInput: AddSingleTransactionInput;
};


export type MutationAddUserToCompanyArgs = {
  addUserToCompanyInput: AddUserToCompanyInput;
};


export type MutationCreateBudgetArgs = {
  createBudgetInput: CreateBudgetInput;
};


export type MutationCreateBudgetCategoryArgs = {
  createBudgetCategoryInput: CreateBudgetCategoryInput;
};


export type MutationCreateCompanyArgs = {
  createCompanyInput: CreateCompanyInput;
};


export type MutationCreateInvoiceArgs = {
  createInvoiceInput: CreateInvoiceInput;
};


export type MutationCreateNotificationArgs = {
  createNotificationInput: CreateNotificationInput;
};


export type MutationCreateOrderArgs = {
  createOrderInput: CreateOrderInput;
};


export type MutationCreatePackageOrderArgs = {
  createPackageOrderInput: CreatePackageOrderInput;
};


export type MutationCreateSubscriptionArgs = {
  CreateSubscriptionInput: CreateSubscriptionInput;
};


export type MutationDeleteBudgetArgs = {
  deleteBudgetInput: BaseDeleteDocumentInput;
};


export type MutationDeleteBudgetCategoryArgs = {
  deleteBudgetCategoryInput: DeleteBudgetCategoryInput;
};


export type MutationDeleteInvoiceArgs = {
  deleteInvoiceInput: BaseDeleteDocumentInput;
};


export type MutationDeleteNotificationArgs = {
  deleteNotificationInput: BaseDeleteDocumentInput;
};


export type MutationDeleteUserFromCompanyArgs = {
  deleteUserFromCompanyInput: DeleteUserFromCompanyInput;
};


export type MutationForgotPasswordArgs = {
  forgotPasswordInput: ForgotPasswordInput;
};


export type MutationInviteUserToCompanyArgs = {
  inviteUserToCompanyInput: InviteUserToCompanyInput;
};


export type MutationLoginArgs = {
  loginInput: LoginInput;
};


export type MutationLoginAdminArgs = {
  loginInput: LoginInput;
};


export type MutationRegisterArgs = {
  registerInput: RegisterInput;
};


export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


export type MutationSendFeedbackArgs = {
  sendFeedbackInput: SendFeedbackInput;
};


export type MutationUpdateBudgetCategoryArgs = {
  updateBudgetCategoryInput: UpdateBudgetCategoryInput;
};


export type MutationUpdateCategoryTreeValueArgs = {
  updateCategoryTreeValueInput: UpdateCategoryTreeValueInput;
};


export type MutationUpdateCompanyArgs = {
  updateCompanyInput: UpdateCompanyInput;
};


export type MutationUpdateCompanyPaymentMethodArgs = {
  updateCompanyPaymentMethodInput: UpdateCompanyPaymentMethodInput;
};


export type MutationUpdateCompanySubscriptionArgs = {
  updateCompanySubscriptionInput: UpdateCompanySubscriptionInput;
};


export type MutationUpdateInvoiceArgs = {
  updateInvoiceInput: UpdateInvoiceInput;
};


export type MutationUpdateInvoicesPaymentStatusArgs = {
  updateInvoicesPaymentsStatusInput: UpdateInvoicesPaymentStatusInput;
};


export type MutationUpdateNotificationArgs = {
  updateNotificationInput: UpdateNotificationInput;
};


export type MutationUpdateSubscriptionArgs = {
  updateSubscriptionInput: UpdateSubscriptionInput;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUploadInvoiceToOrderArgs = {
  uploadInoviceToOrderInput: UploadInoviceToOrderInput;
};


export type MutationUpsertContractorArgs = {
  upsertContractorInput: UpsertContractorInput;
};


export type MutationVerifyAccountArgs = {
  verifyAccountInput: VerifyAccountInput;
};

export type Notification = {
  _id: Scalars['ID']['output'];
  company: Company;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  module?: Maybe<NotificationModuleTypes>;
  recordId?: Maybe<Scalars['ID']['output']>;
  seen: Scalars['Boolean']['output'];
  type: NotificationTypes;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum NotificationModuleTypes {
  Invoices = 'INVOICES'
}

export enum NotificationTypes {
  FinancialMinimumExceeded = 'FINANCIAL_MINIMUM_EXCEEDED',
  LiquidityBelowZero = 'LIQUIDITY_BELOW_ZERO',
  OverdueCost = 'OVERDUE_COST',
  OverdueIncome = 'OVERDUE_INCOME'
}

export type NotificationsFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
};

export type NotificationsList = {
  documents: Array<Notification>;
  total: Scalars['Float']['output'];
};

export type NotificationsSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
};

export type Order = {
  _id: Scalars['ID']['output'];
  company: Company;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  extOrderId?: Maybe<Scalars['String']['output']>;
  invoice?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  package?: Maybe<Package>;
  status: OrderStatus;
  subscription?: Maybe<Subscription>;
  subtotal: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vat: Scalars['Float']['output'];
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  New = 'NEW',
  Pending = 'PENDING'
}

export type OrdersFilterOptionsInput = {
  company?: InputMaybe<FilterOperators>;
  createdAt?: InputMaybe<FilterOperators>;
  number?: InputMaybe<FilterOperators>;
  subscription?: InputMaybe<FilterOperators>;
};

export type OrdersList = {
  documents: Array<Order>;
  total: Scalars['Float']['output'];
};

export type OrdersSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  number?: InputMaybe<SortOptions>;
};

export type Package = {
  OCRPagesCount: Scalars['Float']['output'];
  _id: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  optionsIncluded: Array<Scalars['String']['output']>;
  subtotal: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  type: PackageTypes;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vat: Scalars['Float']['output'];
};

export enum PackageTypes {
  OcrPages = 'OCR_PAGES'
}

export type PackagesFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
};

export type PackagesList = {
  documents: Array<Package>;
  total: Scalars['Float']['output'];
};

export type PackagesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  name?: InputMaybe<SortOptions>;
};

export enum PaymentStatus {
  Paid = 'PAID',
  Unpaid = 'UNPAID'
}

export type Permission = {
  actions: Array<UserPermissionActionsEnum>;
  resource: UserPermissionResourcesEnum;
};

export type PermissionsInput = {
  actions?: Array<UserPermissionActionsEnum>;
  resource: UserPermissionResourcesEnum;
};

export enum PitTaxTypeTypes {
  FlatRate = 'FLAT_RATE',
  Linear = 'LINEAR'
}

export type Query = {
  budget: BudgetResponse;
  budgetCategories: BudgetCategoriesList;
  budgetCostsAndIncomesPerMonth: GetBudgetCostsAndIncomesPerMonthResponse;
  budgetDocuments: BudgetDocumentsList;
  budgets: BudgetsList;
  checkAuth: LoginResponse;
  checkLiquidityAganistFinancialMinimum: BaseSuccessResponse;
  checkOrderStatus: CheckOrderStatusResponse;
  checkOverdueInvoices: BaseSuccessResponse;
  companies: CompaniesList;
  company: Company;
  contractors: Array<Contractor>;
  incomeTax: IncomeTaxResponse;
  invoice: Invoice;
  invoices: InovicesList;
  liquidityAnalysis: LiquidityAnalysisResponse;
  liquidityAnalysisInvoices: InovicesList;
  monthlyLiquidities: MonthlyLiquiditiesResponse;
  notification: Notification;
  notifications: NotificationsList;
  order: Order;
  orders: OrdersList;
  package: Package;
  packages: PackagesList;
  readInvoiceData: ReadInvoiceDataResponse;
  subscription: Subscription;
  subscriptions: SubscriptionsList;
  transactionsCondition: TransactionsConditionResponse;
  unpaidInvoices: UnpaidInvoicesResponse;
  user: User;
  userPermissions: UserPermissionsResponse;
  userSubscriptions: SubscriptionsList;
  users: UsersList;
  vatTax: VatTaxResponse;
};


export type QueryBudgetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBudgetCategoriesArgs = {
  options: GetBudgetCategoriesOptionsInput;
};


export type QueryBudgetCostsAndIncomesPerMonthArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBudgetDocumentsArgs = {
  options: GetBudgetDocumentsOptionsInput;
};


export type QueryBudgetsArgs = {
  options: GetBudgetsOptionsInput;
};


export type QueryCheckLiquidityAganistFinancialMinimumArgs = {
  options: CheckLiquidityAgainstFinancialMinimumOptionsInput;
};


export type QueryCheckOrderStatusArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompaniesArgs = {
  options: GetCompaniesOptionsInput;
};


export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContractorsArgs = {
  options: GetContractorsOptionsInput;
};


export type QueryIncomeTaxArgs = {
  options: GetIncomeTaxArgsOptionsInput;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoicesArgs = {
  options: GetInvoicesOptionsInput;
};


export type QueryLiquidityAnalysisArgs = {
  options: GetLiquidityAnalysisOptionsInput;
};


export type QueryLiquidityAnalysisInvoicesArgs = {
  options: GetInvoicesOptionsInput;
};


export type QueryMonthlyLiquiditiesArgs = {
  options: GetLiquidityAnalysisOptionsInput;
};


export type QueryNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNotificationsArgs = {
  options: GetNotificationsOptionsInput;
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrdersArgs = {
  options: GetOrdersOptionsInput;
};


export type QueryPackageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPackagesArgs = {
  options: GetPackagesOptionsInput;
};


export type QueryReadInvoiceDataArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
};


export type QuerySubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySubscriptionsArgs = {
  options: GetSubscriptionsOptionsInput;
};


export type QueryTransactionsConditionArgs = {
  options: GetTransactionsConditionOptionsInput;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserPermissionsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserSubscriptionsArgs = {
  options: GetSubscriptionsOptionsInput;
};


export type QueryUsersArgs = {
  options: GetUsersOptionsInput;
};


export type QueryVatTaxArgs = {
  options: GetVatTaxArgsOptionsInput;
};

export type ReadInvoiceDataCompany = {
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
};

export type ReadInvoiceDataResponse = {
  billFrom?: Maybe<ReadInvoiceDataCompany>;
  billTo?: Maybe<ReadInvoiceDataCompany>;
  interest?: Maybe<Scalars['Float']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  issueDate?: Maybe<Scalars['DateTime']['output']>;
  paymentDue?: Maybe<Scalars['DateTime']['output']>;
  saleDate?: Maybe<Scalars['DateTime']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  vat?: Maybe<Scalars['Float']['output']>;
};

export type RegisterInput = {
  companyAddress: Scalars['String']['input'];
  companyCity: Scalars['String']['input'];
  companyEmail: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  companyPhone: Scalars['String']['input'];
  companyPostalCode: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type RegisterResponse = {
  _id: Scalars['ID']['output'];
};

export type ResetPasswordInput = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type ResetPasswordResponse = {
  success: Scalars['Boolean']['output'];
};

export type SendFeedbackInput = {
  category: Scalars['String']['input'];
  content: Scalars['String']['input'];
  email: Scalars['String']['input'];
  file?: InputMaybe<Scalars['Upload']['input']>;
  name: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export enum SortOptions {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Subscription = {
  OCRPagesCount: Scalars['Float']['output'];
  _id: Scalars['ID']['output'];
  budgetingEnabled: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  emailNotificationsEnabled: Scalars['Boolean']['output'];
  maxUsersCount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  optionsExcluded: Array<Scalars['String']['output']>;
  optionsIncluded: Array<Scalars['String']['output']>;
  permissionsEnabled: Scalars['Boolean']['output'];
  subtotal: Scalars['Float']['output'];
  taxesCalculationEnabled: Scalars['Boolean']['output'];
  total: Scalars['Float']['output'];
  trialSubtotal: Scalars['Float']['output'];
  trialTotal: Scalars['Float']['output'];
  trialVat: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vat: Scalars['Float']['output'];
};

export type SubscriptionsFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
  name?: InputMaybe<FilterOperators>;
};

export type SubscriptionsList = {
  documents: Array<Subscription>;
  total: Scalars['Float']['output'];
};

export type SubscriptionsSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  name?: InputMaybe<SortOptions>;
};

export enum TaxSettlementPeriodType {
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Year = 'YEAR'
}

export enum TaxTypes {
  Cit = 'CIT',
  Pit = 'PIT'
}

export type TransactionsConditionFilterOptionsInput = {
  paymentDue?: InputMaybe<FilterOperators>;
};

export type TransactionsConditionResponse = {
  percentageCosts: Scalars['Float']['output'];
  percentageIncomes: Scalars['Float']['output'];
  totalCosts: Scalars['Float']['output'];
  totalIncomes: Scalars['Float']['output'];
};

export type UnpaidInvoicesResponse = {
  unpaidCosts: Scalars['Float']['output'];
  unpaidCostsCount: Scalars['Float']['output'];
  unpaidIncomes: Scalars['Float']['output'];
  unpaidIncomesCount: Scalars['Float']['output'];
};

export type UpdateBudgetCategoryInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateCategoryTreeValueInput = {
  id: Scalars['ID']['input'];
  path: Scalars['String']['input'];
  type: BudgetCategoryTypes;
  value: Scalars['Float']['input'];
};

export type UpdateCompanyInput = {
  accountBalance?: InputMaybe<Scalars['Float']['input']>;
  additionalOcrPages?: InputMaybe<Scalars['Float']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  integrationData?: InputMaybe<CompanyIntegrationDataInput>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  legalForm?: InputMaybe<CompanyLegalFormTypes>;
  name?: InputMaybe<Scalars['String']['input']>;
  newCompany?: InputMaybe<Scalars['Boolean']['input']>;
  newSubscription?: InputMaybe<Scalars['ID']['input']>;
  ocrPagesLeft?: InputMaybe<Scalars['Float']['input']>;
  paymentMethod?: InputMaybe<CompanyPaymentMethodInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  startAccountBalance?: InputMaybe<Scalars['Float']['input']>;
  startAccountValuesDate?: InputMaybe<Scalars['DateTime']['input']>;
  startVatAccountBalance?: InputMaybe<Scalars['Float']['input']>;
  subscription?: InputMaybe<CompanySubscriptionInput>;
  taxDetails?: InputMaybe<CompanyTaxDetailsInput>;
  trialUsed?: InputMaybe<Scalars['Boolean']['input']>;
  vatAccountBalance?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCompanyPaymentMethodInput = {
  token: Scalars['String']['input'];
};

export type UpdateCompanyPaymentMethodResponse = {
  maskedCardNumber: Scalars['String']['output'];
  redirectUri: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateCompanySubscriptionInput = {
  subscription: Scalars['ID']['input'];
};

export type UpdateInvoiceInput = {
  billFrom?: InputMaybe<InvoiceCompanyInput>;
  billTo?: InputMaybe<InvoiceCompanyInput>;
  capital?: InputMaybe<Scalars['Float']['input']>;
  car?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Currency>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalService?: InputMaybe<CompanyIntegrationTypes>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  filePath?: InputMaybe<Scalars['String']['input']>;
  genre?: InputMaybe<InvoiceGenres>;
  id: Scalars['String']['input'];
  interest?: InputMaybe<Scalars['Float']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  issueDate?: InputMaybe<Scalars['DateTime']['input']>;
  leftToPay?: InputMaybe<Scalars['Float']['input']>;
  paymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  paymentDue?: InputMaybe<Scalars['DateTime']['input']>;
  paymentStatus?: InputMaybe<PaymentStatus>;
  saleDate?: InputMaybe<Scalars['DateTime']['input']>;
  settlementPeriod?: InputMaybe<Scalars['DateTime']['input']>;
  settlementPeriodType?: InputMaybe<TaxSettlementPeriodType>;
  splitPayment?: InputMaybe<Scalars['Boolean']['input']>;
  subtotal?: InputMaybe<Scalars['Float']['input']>;
  subtype?: InputMaybe<InvoiceSubtypes>;
  total?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<InvoiceTypes>;
  valueFromAccount?: InputMaybe<Scalars['Float']['input']>;
  valueFromVatAccount?: InputMaybe<Scalars['Float']['input']>;
  vat?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateInvoicesPaymentStatusDocumentsInput = {
  id: Scalars['ID']['input'];
  isSplitPayment?: InputMaybe<Scalars['Boolean']['input']>;
  paymentDate: Scalars['DateTime']['input'];
};

export type UpdateInvoicesPaymentStatusInput = {
  documents: Array<UpdateInvoicesPaymentStatusDocumentsInput>;
};

export type UpdateNotificationInput = {
  id: Scalars['ID']['input'];
  seen?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateSubscriptionInput = {
  OCRPagesCount?: InputMaybe<Scalars['Float']['input']>;
  _id: Scalars['String']['input'];
  budgetingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  emailNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  maxUsersCount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  optionsExcluded?: InputMaybe<Array<Scalars['String']['input']>>;
  optionsIncluded?: InputMaybe<Array<Scalars['String']['input']>>;
  permissionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  subtotal?: InputMaybe<Scalars['Float']['input']>;
  total?: InputMaybe<Scalars['Float']['input']>;
  trialSubtotal?: InputMaybe<Scalars['Float']['input']>;
  trialTotal?: InputMaybe<Scalars['Float']['input']>;
  trialVat?: InputMaybe<Scalars['Float']['input']>;
  vat?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateUserInput = {
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  financialMinimum?: InputMaybe<Array<FinancialMinimumInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<PermissionsInput>>;
  phone?: InputMaybe<Scalars['Float']['input']>;
};

export type UploadInoviceToOrderInput = {
  file: Scalars['Upload']['input'];
  id: Scalars['String']['input'];
};

export type UploadInvoiceToOrderResponse = {
  _id: Scalars['ID']['output'];
};

export type UpsertContractorInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  _id: Scalars['ID']['output'];
  company?: Maybe<Company>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  orders?: Maybe<Array<Order>>;
  permissions: Array<Permission>;
  phone: Scalars['Float']['output'];
  role: UserRoles;
  settings: UserSettings;
  subscribed: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum UserPermissionActionsEnum {
  Create = 'CREATE',
  Delete = 'DELETE',
  Get = 'GET',
  Getlist = 'GETLIST',
  Update = 'UPDATE'
}

export enum UserPermissionResourcesEnum {
  Account = 'ACCOUNT',
  Budget = 'BUDGET',
  Cockpit = 'COCKPIT',
  FinancialDetails = 'FINANCIAL_DETAILS',
  LiquidityAnalysis = 'LIQUIDITY_ANALYSIS',
  PaymentsCalendar = 'PAYMENTS_CALENDAR',
  Transactions = 'TRANSACTIONS'
}

export type UserPermissionsResponse = {
  permissions: Array<Permission>;
};

export enum UserRoles {
  Admin = 'ADMIN',
  User = 'USER'
}

export type UserSettings = {
  financialMinimum: Array<FinancialMinimum>;
};

export type UsersFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
  firstName?: InputMaybe<FilterOperators>;
  lastName?: InputMaybe<FilterOperators>;
};

export type UsersList = {
  documents: Array<User>;
  total: Scalars['Float']['output'];
};

export type UsersSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  firstName?: InputMaybe<SortOptions>;
};

export type VatTaxFilterOptionsInput = {
  date?: InputMaybe<FilterOperators>;
};

export type VatTaxResponse = {
  vatTaxValue?: Maybe<Scalars['Float']['output']>;
};

export enum VatTaxTypes {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

export type VerifyAccountInput = {
  code: Scalars['String']['input'];
};

export type VerifyAccountResponse = {
  companyEmail: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};
