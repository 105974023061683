import React, { useEffect } from "react";

import { openFileInExternalWindow } from "src/utils/openFile";

import { useCreateThumbnail } from "src/common/hooks/useCreateThumbnail";

import MagnifierIcon from "src/assets/images/magnifier.svg";

type Props = {
  file: Blob;
  id?: string;
};

const Thumnbail: React.FC<Props> = ({ file, id = "thumbnail" }) => {
  const { createThumbnail } = useCreateThumbnail();

  useEffect(() => {
    if (file) {
      createThumbnail(file, id);
    }
  }, [file]);

  return (
    <div
      id={id}
      className="relative h-[190px] cursor-pointer drop-shadow-xl"
      onClick={() => openFileInExternalWindow(file)}
    >
      <div className="absolute flex w-full h-full justify-center items-center">
        <img src={MagnifierIcon} />
      </div>
    </div>
  );
};

export default React.memo(Thumnbail);
