import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CreateInvoiceInput, InvoiceGenres, InvoiceTypes } from "src/api/graphql/types-and-hooks";

import {
  CarGenreOptions,
  InvoicesGenresOptions,
  InvoiceTypesOptions,
} from "src/pages/invoice/addMultipleInvoices/addMultipleInvoices.consts";
import InvoiceOptionsModal from "src/pages/invoice/modals/InvoiceOptionsModal";
import InvoicesToAddList from "src/pages/invoice/addMultipleInvoices/InvoicesToAddList";
import InvoicesUploadModal from "src/pages/invoice/modals/InvoicesUploadModal";
import MultipleInvoiceForm from "src/pages/invoice/forms/MultipleInvoiceForm";
import { useCreateInvoice, useReadDataFromInvoice } from "src/pages/invoice/addMultipleInvoices/multipleInvoice.hooks";
import { useCheckLiqudityAgainstFinancialMinimums } from "src/pages/invoice/invoice.hooks";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { getInvoicesPath } from "src/common/router/routerPaths";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

import { subtractPrices } from "src/utils/currency";
import { addToDate, dateToTimestamp } from "src/utils/time";

type Props = {
  onGoBack: () => void;
  startAccountValuesDate: Date;
};

type InvoiceToAdd = {
  formValues: Partial<CreateInvoiceInput>;
  isAdded?: boolean;
  isRejected?: boolean;
  isSaved?: boolean;
  isError?: boolean;
  isOcrError?: boolean;
  isLoading?: boolean;
  fileName?: string;
};

const AddMultipleInvoice: React.FC<Props> = ({ onGoBack, startAccountValuesDate }) => {
  const [step, setStep] = useState(1);
  const [invoiceType, setInvoiceType] = useState(null);
  const [invoiceGenre, setInvoiceGenre] = useState(null);
  const [invoiceSubtype, setInvoiceSubtype] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [billData, setBillData] = useState(null);
  const [useOcr, setUseOcr] = useState(false);
  const [invoices, setInvoices] = useState<InvoiceToAdd[]>([]);
  const [activeInvoiceIndex, setActiveInvoiceIndex] = useState<number | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleReadDataFromInvoice } = useReadDataFromInvoice();
  const { handleCreateInvoice } = useCreateInvoice();
  const { checkLiquitiyAgainstFinancialMinimum } = useCheckLiqudityAgainstFinancialMinimums();

  const userInfo = invoiceType === InvoiceTypes.Income ? { billFrom: billData } : { billTo: billData };

  useEffect(() => {
    if (currentUser) {
      const { company } = currentUser;
      setBillData({
        address: company?.address,
        name: company?.name,
        city: company?.city,
        postcode: company?.postalCode,
        country: company?.country,
        companyId: company?.companyId,
      });
    }
  }, [currentUser]);

  const memoizedInvoices = useMemo(
    () =>
      invoices.map(({ fileName, isLoading, isAdded, isRejected, isSaved, isError, isOcrError }) => ({
        fileName,
        isLoading,
        isAdded,
        isSaved,
        isError,
        isRejected,
        isOcrError,
      })),
    [invoices],
  );

  const handleInvoiceTypeConfirm = () => {
    if (invoiceType === InvoiceTypes.Income) {
      setInvoiceGenre(InvoiceGenres.Invoice);
      setShowUploadModal(true);
      setStep(3);
    } else {
      setStep(2);
    }
  };

  const handleInvoiceGenreConfirm = () => {
    setStep(3);
    if (invoiceGenre === InvoiceGenres.Invoice) {
      setShowUploadModal(true);
    }
  };

  const handleInvoiceSubtypeConfirm = () => {
    setStep(4);
    setShowUploadModal(true);
  };

  const handleUploadModalCancel = () => {
    setShowUploadModal(false);
    onGoBack();
  };

  const readDataFromInvoices = async (data: InvoiceToAdd[]) => {
    const updatedInvoices = [];

    for (const invoiceToRead of data) {
      try {
        const ocrData = await handleReadDataFromInvoice(invoiceToRead.formValues.file);
        updatedInvoices.push({
          ...invoiceToRead,
          formValues: {
            ...invoiceToRead.formValues,
            ...ocrData,
            ...userInfo,
          },
          isLoading: false,
        });
      } catch (error) {
        updatedInvoices.push({
          ...invoiceToRead,
          isLoading: false,
          isOcrError: true,
        });
      }
    }

    setInvoices(updatedInvoices);
    setActiveInvoiceIndex(0);
  };

  const handleUploadModalConfirm = async (files: File[]) => {
    if (!files.length) return;
    setShowUploadModal(false);

    const data = files.map((file) => {
      return {
        fileName: file.name,
        formValues: {
          file,
          type: invoiceType,
          genre: invoiceGenre,
          subtype: invoiceSubtype,
          ...userInfo,
        },
        isLoading: useOcr,
      };
    });
    setInvoices(data);

    if (useOcr) {
      readDataFromInvoices(data);
    } else {
      setActiveInvoiceIndex(0);
    }
  };

  // setTimeout to trigger "reload" of the form
  const handleInvoiceClick = useCallback(
    (index: number) => {
      setActiveInvoiceIndex(null);
      setTimeout(() => {
        setActiveInvoiceIndex(index);
      }, 100);
    },
    [invoices],
  );

  // set isRejected to true which means that invoice will not be saved
  const handleRejectInvoice = (data: any) => {
    const updatedInvoices = [...invoices];

    updatedInvoices[activeInvoiceIndex] = {
      ...updatedInvoices[activeInvoiceIndex],
      formValues: data,
      isRejected: true,
      isAdded: false,
    };
    setInvoices(updatedInvoices);
  };

  // calculate vat and leftToPay, set isAdded to true which means that invoice is valid
  // and will be included to save
  const handleAddInvoice = (data: any) => {
    const updatedInvoices = [...invoices];
    const leftToPay = 0;
    const vat = subtractPrices([data.total, data.subtotal]);

    updatedInvoices[activeInvoiceIndex] = {
      ...updatedInvoices[activeInvoiceIndex],
      formValues: { ...data, leftToPay, vat },
      isAdded: true,
      isRejected: false,
    };

    setInvoices(updatedInvoices);
  };

  // handle result of saving invoices, show toast with number of saved and not saved invoices
  // if all invoices are saved, redirect to invoices list
  const handleSaveResult = (results: InvoiceToAdd[]) => {
    const savedCount = results.filter((invoice) => invoice.isSaved).length;
    if (savedCount > 0) {
      useToastSuccess(`Liczba zapisanych faktur: ${savedCount}`);
    }

    const errorsCount = results.filter((invoice) => invoice.isError).length;
    if (errorsCount > 0) {
      useToastError(`Liczba niezapisanych faktur: ${errorsCount}`);
    } else {
      const newestPaymentDueValue = results
        .filter((invoice) => invoice.isSaved)
        .reduce((acc, invoice) => {
          return invoice.formValues.paymentDue > acc ? invoice.formValues.paymentDue : acc;
        }, new Date(0));

      const earliestPaymentDueValue = results
        .filter((invoice) => invoice.isSaved)
        .reduce((acc, invoice) => {
          return invoice.formValues.paymentDue < acc ? invoice.formValues.paymentDue : acc;
        }, new Date());

      if (earliestPaymentDueValue && newestPaymentDueValue) {
        checkLiquitiyAgainstFinancialMinimum({
          gte: dateToTimestamp(earliestPaymentDueValue),
          lte: dateToTimestamp(addToDate(newestPaymentDueValue, 3, "months")),
        });
      }

      navigate(getInvoicesPath());
    }
  };

  const handleSaveInvoices = async (invoicesToSave: InvoiceToAdd[]) => {
    setIsSaving(true);
    setActiveInvoiceIndex(null);

    const updatedInvoices = [...invoices];
    for (let i = 0; i < invoicesToSave.length; i++) {
      const invoice = invoicesToSave[i];
      const dataWithoutNulls = Object.fromEntries(Object.entries(invoice.formValues).filter(([_, v]) => v != null));

      try {
        await handleCreateInvoice(dataWithoutNulls as CreateInvoiceInput);
        updatedInvoices[invoices.indexOf(invoice)] = {
          ...invoice,
          isSaved: true,
          isError: false,
        };
      } catch (error) {
        updatedInvoices[invoices.indexOf(invoice)] = {
          ...invoice,
          isError: true,
        };
      }
    }

    setInvoices(updatedInvoices);
    setIsSaving(false);

    handleSaveResult(updatedInvoices);
  };

  // check if all invoices have isAdded or isRejected prop set to true
  // check if at least one invoice has isAdded prop set to true
  const onSaveInvoices = () => {
    const isAllInvoicesSaved = invoices.every((invoice) => invoice.isAdded || invoice.isRejected);
    if (!isAllInvoicesSaved) return useToastError(t("Add-or-reject-all-invoices"));

    const invoicesToSave = invoices.filter((invoice) => invoice.isAdded && !invoice.isSaved);
    if (!invoicesToSave.length) return useToastError(t("No-invoices-to-save"));

    return handleSaveInvoices(invoicesToSave);
  };

  return (
    <>
      {showUploadModal && (
        <InvoicesUploadModal
          onUseOcr={setUseOcr}
          onCancel={handleUploadModalCancel}
          onConfirm={handleUploadModalConfirm}
          isMultiple
        />
      )}
      {step === 1 && (
        <InvoiceOptionsModal
          onGoBack={onGoBack}
          onChange={(val) => setInvoiceType(val)}
          onConfirm={handleInvoiceTypeConfirm}
          options={InvoiceTypesOptions}
        />
      )}
      {step === 2 && (
        <InvoiceOptionsModal
          onGoBack={() => setStep(1)}
          onChange={(val) => setInvoiceGenre(val)}
          onConfirm={handleInvoiceGenreConfirm}
          options={InvoicesGenresOptions}
          subtitle="Select-document-genre-and-specify-cost-deduction"
        />
      )}
      {step === 3 && invoiceGenre === InvoiceGenres.Car && (
        <InvoiceOptionsModal
          onGoBack={() => setStep(2)}
          onChange={(val) => setInvoiceSubtype(val)}
          onConfirm={handleInvoiceSubtypeConfirm}
          options={CarGenreOptions}
        />
      )}
      <div className="grid grid-cols-5 gap-2 h-full">
        <div className="col-span-1">
          <InvoicesToAddList
            invoices={memoizedInvoices}
            onClick={handleInvoiceClick}
            onSaveInvoices={onSaveInvoices}
            isLoading={isSaving}
            currentActiveIndex={activeInvoiceIndex}
          />
        </div>
        <div className="col-span-4">
          {activeInvoiceIndex !== null && (
            <MultipleInvoiceForm
              formValues={invoices[activeInvoiceIndex].formValues}
              startAccountValuesDate={startAccountValuesDate}
              onRejectInvoice={handleRejectInvoice}
              onAddInvoice={handleAddInvoice}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AddMultipleInvoice;
